import React from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {altegio, amo, input, lang, monday, sip} from "../translate";
import CloseIcon from "@mui/icons-material/Close";
const {REACT_APP_SERVER} = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // height: window.innerWidth > 600?'50vh':'97vh',
    overflow: 'auto'
};
function AltegioInstall({open,setOpen}) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {altegio.header[+localStorage.getItem('atsLang')  || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    <div className="call_settings">
                        <div>
                            {REACT_APP_SERVER === 'ATS'?altegio.titleA[+localStorage.getItem('atsLang')  || 1]:altegio.title[+localStorage.getItem('atsLang')  || 1]}
                        </div>

                        <p className="crm_paragraph">
                            {altegio.paragraph_p1[+localStorage.getItem('atsLang')  || 1]}
                            <a href={REACT_APP_SERVER === 'ATS'?altegio.link.linkA : altegio.link.link} target='_blank'>{REACT_APP_SERVER === 'ATS'?altegio.link.textA[+localStorage.getItem('atsLang')  || 1]:altegio.link.text[+localStorage.getItem('atsLang')  || 1]}</a>{altegio.paragraph_p2[+localStorage.getItem('atsLang')  || 1]}
                        </p>
                        <button onClick={() => setOpen('')} style={{marginTop:20}} className="addBtn">
                            OK
                        </button>

                    </div>

                </Box>
            </Fade>
        </Modal>
    );
}


export default AltegioInstall;
