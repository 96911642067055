export const SHORT_NUMBER_REQUEST = 'SHORT_NUMBER_REQUEST';
export const SHORT_NUMBER_SUCCESS = 'SHORT_NUMBER_SUCCESS';
export const SHORT_NUMBER_FAIL = 'SHORT_NUMBER_FAIL';

export function shortRequest(limit,page,search,sort,sortBy) {
    return {
        type: SHORT_NUMBER_REQUEST,
        payload: {limit,page,search,sort,sortBy},
    };
}

export const SHORT_NUMBER_ADD_LOCAL = 'SHORT_NUMBER_ADD_LOCAL';

export function shortNumber(data) {
    return {
        type: SHORT_NUMBER_ADD_LOCAL,
        payload: {data},
    };
}

export const SHORT_CREATE_REQUEST = 'SHORT_CREATE_REQUEST';
export const SHORT_CREATE_SUCCESS = 'SHORT_CREATE_SUCCESS';
export const SHORT_CREATE_FAIL = 'SHORT_CREATE_FAIL';

export function shortNumberCreate(limit,page,search,form,sort,sortBy) {
    return {
        type: SHORT_CREATE_REQUEST,
        payload: {limit,page,search,form,sort,sortBy},
    };
}

export const SHORT_EDIT_REQUEST = 'SHORT_EDIT_REQUEST';
export const SHORT_EDIT_SUCCESS = 'SHORT_EDIT_SUCCESS';
export const SHORT_EDIT_FAIL = 'SHORT_EDIT_FAIL';

export function shortNumberEdit(limit,page,search,id,form,sort,sortBy) {
    return {
        type: SHORT_EDIT_REQUEST,
        payload: {limit,page,search,id,form,sort,sortBy},
    };
}

export const SHORT_NUMBER_DELETE_LOCAL = 'SHORT_NUMBER_DELETE_LOCAL';

export function shortNumberDelete(id) {
    return {
        type: SHORT_NUMBER_DELETE_LOCAL,
        payload: {id},
    };
}
