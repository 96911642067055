export const BLOCKED_REQUEST = 'BLOCKED_REQUEST';
export const BLOCKED_SUCCESS = 'BLOCKED_SUCCESS';
export const BLOCKED_FAIL = 'BLOCKED_FAIL';

export function blockedRequest(limit,page,search) {
  return {
    type: BLOCKED_REQUEST,
    payload: { limit,page,search },
  };
}
export const BLOCKED_CREATE_REQUEST = 'BLOCKED_CREATE_REQUEST';
export const BLOCKED_CREATE_SUCCESS = 'BLOCKED_CREATE_SUCCESS';
export const BLOCKED_CREATE_FAIL = 'BLOCKED_CREATE_FAIL';

export function blockedCreateRequest(limit,page,search,form) {
  return {
    type: BLOCKED_CREATE_REQUEST,
    payload: { limit,page,search,form },
  };
}
export const BLOCKED_ADD_LOCAL = 'BLOCKED_ADD_LOCAL';

export function blockedLocal(data) {
  return {
    type: BLOCKED_ADD_LOCAL,
    payload: { data },
  };
}
export const BLOCKED_DELETE_LOCAL = 'BLOCKED_DELETE_LOCAL';

export function blockedDeleteLocal(id) {
  return {
    type: BLOCKED_DELETE_LOCAL,
    payload: { id },
  };
}



