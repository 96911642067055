import { takeLatest, call, put } from 'redux-saga/effects';
import {

    INCLUDE_REQUEST,
    INCLUDE_SUCCESS,
    INCLUDE_FAIL,
    PROVIDER_FAIL,
    PROVIDER_SUCCESS,
    PROVIDER_REQUEST,
    INCLUDE_CREATE_REQUEST,
    INCLUDE_CREATE_FAIL,
    INCLUDE_CREATE_SUCCESS,
    INCLUDE_EDIT_SUCCESS,
    INCLUDE_EDIT_FAIL,
    INCLUDE_EDIT_REQUEST,
    INCLUDE_UPLOAD_SUCCESS,
    INCLUDE_UPLOAD_FAIL, INCLUDE_UPLOAD_REQUEST,

} from '../actions/included';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleAllowCreate(action) {
    try {
        const {limit,page,search,form,multiple,sort,sortBy} = action.payload
        const { data } = yield call(Api.allowedCreate, limit,page,search,form,multiple,sort,sortBy);
        toast.success(global.create[+localStorage.getItem('atsLang')])
        data.info.map(s => toast.info(s))
        yield put({
            type: INCLUDE_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: INCLUDE_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleAllowEdit(action) {
    try {
        const {limit,page,search,form,sort,sortBy} = action.payload
        const { data } = yield call(Api.allowedEdit, limit,page,search,form,sort,sortBy);
        toast.success(global.success[+localStorage.getItem('atsLang')])

        yield put({
            type: INCLUDE_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: INCLUDE_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleAllow(action) {
    try {
        const {limit,page,search,sort,sortBy} = action.payload
        const { data } = yield call(Api.allowed, limit,page,search,sort,sortBy);
        yield put({
            type: INCLUDE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: INCLUDE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleAllowUpload(action) {
    try {
        const {form,limit,page,search,sort,sortBy} = action.payload
        const { data } = yield call(Api.allowedUpload, form,limit,page,search,sort,sortBy);
        if(data.exist.length){
            data.exist.map(s => toast.info(s))
        }
        toast.success(global.create[+localStorage.getItem('atsLang')])
        yield put({
            type: INCLUDE_UPLOAD_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: INCLUDE_UPLOAD_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleProvider() {
    try {
        const { data } = yield call(Api.provider);
        yield put({
            type: PROVIDER_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: PROVIDER_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(INCLUDE_CREATE_REQUEST, handleAllowCreate);
    yield takeLatest(INCLUDE_UPLOAD_REQUEST, handleAllowUpload);
    yield takeLatest(INCLUDE_EDIT_REQUEST, handleAllowEdit);
    yield takeLatest(INCLUDE_REQUEST, handleAllow);
    yield takeLatest(PROVIDER_REQUEST, handleProvider);
}
