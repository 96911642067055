import React, {useCallback, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, sip, menu, short, weekDays} from "../translate";
import {useDispatch} from "react-redux";
import {ipCreateRequest, ipLocal} from "../store/actions/ip";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";
import {toast} from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 750 ? 750 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerWidth > 565 ? '80vh' : '97vh',
    overflow: 'auto'
};

function SipToShort({open, setOpen}) {
    const [form, setForm] = useState({
        weekStart: weekDays[1][+localStorage.getItem('atsLang')  || 1 ],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang')  || 1 ],
        timeStart: '00:00',
        timeEnd: '23:59',
    })
    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async () => {
        try {
            await Api.dragCreateSip({...open,...form})
            toast.success('Success')
            setOpen({})
        }catch (e) {
            toast.error('Something went wrong')

        }
    }, [form,open])
    const handleTime = (name, value) => {
        const input = value;
        if (/^([0-9]{0,2}):?([0-9]{0,2})?$/.test(input) || input === '') {
            setForm((prevState) => ({
                ...prevState,
                [name]: input,
            }))
        }
    };

    const handleBlur = (name) => {
        if (form[name]) {
            const [hours, minutes] = form[name].split(':').map(Number);
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            setForm((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        }
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={!!open.start}
            onClose={() => setOpen({})}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={!!open.start}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            SIP to Group
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen({})}>
                                                         <CloseIcon/>

                        </span>
                    </div>
                    <div className="row" style={{margin: '30px 0'}}>
                        <div className="col-md-6">
                            <p style={{margin: '30px 0'}}>{short.week[+localStorage.getItem('atsLang')  || 1 ]}</p>
                            <select value={form.weekStart} onChange={(ev) => handleChange('weekStart',ev.target.value)} style={{width: '40%', border: '1px solid black', height: 40}}>
                                <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                {weekDays.map((k,_) => (
                                    <option key={_} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                ))}
                            </select>
                            <span>-</span>
                            <select value={form.weekEnd} onChange={(ev) => handleChange('weekEnd',ev.target.value)} style={{width: '40%',border: '1px solid black', height: 40}}>
                                <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                {weekDays.map((k,_) => (
                                    <option key={_} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <p style={{margin: '30px 0'}}>{short.time[+localStorage.getItem('atsLang')  || 1 ]}</p>
                            <input
                                type="text"
                                value={form.timeStart}
                                onChange={(ev) => handleTime('timeStart',ev.target.value)}
                                onBlur={() => handleBlur('timeStart')}
                                placeholder="HH:mm"
                                style={{
                                    width: '40%',
                                    textAlign: 'center',
                                    border: '1px solid',
                                    height: 35,
                                    fontSize: 14
                                }}
                            />
                            <span>-</span>
                            <input
                                type="text"
                                value={form.timeEnd}
                                onChange={(ev) => handleTime('timeEnd',ev.target.value)}
                                onBlur={() => handleBlur('timeEnd')}
                                placeholder="HH:mm"
                                style={{
                                    width: '40%',
                                    textAlign: 'center',
                                    border: '1px solid',
                                    height: 35,
                                    fontSize: 14
                                }}
                            />

                        </div>
                    </div>
                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtnBig">
                            {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default SipToShort;
