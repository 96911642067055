import React, {useEffect, useState} from 'react';
import Api from "../Api";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
    BarElement
} from "chart.js";
import {Bar, Line, Pie} from "react-chartjs-2";
import moment from "moment";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {useSelector} from "react-redux";
import Utils from "../Utils";

ChartJS.register(ArcElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement, LineElement, BarElement);
let date = new Date()
let timeZoneStart = 'T00:00:00Z';
let timeZoneEnd = 'T23:59:59Z';
let startTime = moment(date).format('YYYY-MM-DD');
let endTime = moment(date).format('YYYY-MM-DD');
let timeStartStart = startTime + timeZoneStart;
let timeEndEnd = endTime + timeZoneEnd;

function Graphics(props) {
    const [pie, setPie] = useState({})
    const [pie2, setPie2] = useState({})
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState({})
    const [disposition, setDisposition] = useState({})
    const [newObj, setObj] = useState({})
    const [newObjD, setObjD] = useState({})
    const [duration, setDuration] = useState({})
    const [rate, setRate] = useState({})
    const [durationAll, setDurationAll] = useState({})
    const historyData = useSelector(state => state.history.historyData)
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    useEffect(() => {
        (async () => {
            try {
                setLoader(true)
                let isTrue = moment(query.endTime ? query.endTime + timeZoneEnd : timeEndEnd).diff(moment(query.startTime ? query.startTime + timeZoneStart : timeStartStart), "months") < 1;
                const {data} = await Api.graphic('status', isTrue, {
                    timeStartStart: query.startTime ? query.startTime + timeZoneStart : timeStartStart,
                    timeEndEnd: query.endTime ? query.endTime + timeZoneEnd : timeEndEnd
                })
                let allData = data.facet_counts.facet_pivot.status;
                let callName;
                let arr = {};
                let obj = {};
                let range;
                for (let i = 0; i < allData.length; i++) {
                    range = data.facet_counts.facet_pivot.status[i].ranges.start.counts;
                    callName = data.facet_counts.facet_pivot.status[i].value
                    let year;
                    let count;
                    let yearSplit = [...range];
                    let countSplit = [...range];
                    let date;
                    let dateArr;
                    obj = {};
                    for (let j = 0; j < range.length / 2; j++) {
                        year = yearSplit.splice(0, 2)[0]
                        count = countSplit.splice(0, 2)[1]
                        if (isTrue) {
                            date = String(new Date(year));
                            dateArr = date.split(' ');
                            let dateYear = dateArr[2];
                            let dateMonth = dateArr[1];
                            dateArr = dateMonth + ' ' + dateYear;
                            year = dateArr;
                            obj[year] = count;
                        } else {
                            if (
                                ((moment(year).isAfter(query.startTime ? query.startTime + timeZoneStart : timeStartStart) || new Date(year).getMonth() === new Date(query.startTime ? query.startTime + timeZoneStart : timeStartStart).getMonth()) && moment(year).isBefore(query.endTime ? query.endTime + timeZoneEnd : timeEndEnd))
                                || moment(year).isSame(query.startTime ? query.startTime + timeZoneStart : timeStartStart) || moment(year).isSame(query.endTime ? query.endTime + timeZoneEnd : timeEndEnd)
                            ) {
                                date = String(new Date(year));
                                dateArr = date.split(' ');
                                dateArr.slice(1, 4);
                                let dateYear = dateArr[3];
                                let dateMonth = dateArr[1];
                                dateArr = dateMonth + ' ' + dateYear;
                                year = dateArr;
                                obj[year] = count;
                            }
                        }

                    }
                    arr[callName] = obj
                    setStatus(arr)
                    setObj(obj)
                }
                const disposition = await Api.graphic('disposition', isTrue, {
                    timeStartStart: query.startTime ? query.startTime + timeZoneStart : timeStartStart,
                    timeEndEnd: query.endTime ? query.endTime + timeZoneEnd : timeEndEnd
                })
                allData = disposition.data.facet_counts.facet_pivot.disposition;
                arr = {};
                obj = {};
                for (let i = 0; i < allData.length; i++) {
                    range = disposition.data.facet_counts.facet_pivot.disposition[i].ranges.start.counts;
                    callName = disposition.data.facet_counts.facet_pivot.disposition[i].value
                    let timeRange;
                    let count;
                    let timeRangeSplit = [...range];
                    let countSplit = [...range];
                    let date;
                    let dateArr;
                    obj = {};
                    for (let j = 0; j < range.length / 2; j++) {
                        timeRange = timeRangeSplit.splice(0, 2)[0]
                        count = countSplit.splice(0, 2)[1]
                        if (isTrue) {
                            date = String(new Date(timeRange));
                            dateArr = date.split(' ');
                            let dateYear = dateArr[2];
                            let dateMonth = dateArr[1];
                            dateArr = dateMonth + ' ' + dateYear;
                            timeRange = dateArr;
                            obj[timeRange] = count;
                        } else {
                            if (
                                ((moment(timeRange).isAfter(query.startTime ? query.startTime + timeZoneStart : timeStartStart) || new Date(timeRange).getMonth() === new Date(query.startTime ? query.startTime + timeZoneStart : timeStartStart).getMonth()) && moment(timeRange).isBefore(query.endTime ? query.endTime + timeZoneEnd : timeEndEnd))
                                || moment(timeRange).isSame(query.startTime ? query.startTime + timeZoneStart : timeStartStart) || moment(timeRange).isSame(query.endTime ? query.endTime + timeZoneEnd : timeEndEnd)
                            ) {
                                date = String(new Date(timeRange));
                                dateArr = date.split(' ');
                                dateArr.slice(1, 4);
                                let dateYear = dateArr[3];
                                let dateMonth = dateArr[1];
                                dateArr = dateMonth + ' ' + dateYear;
                                timeRange = dateArr;
                                obj[timeRange] = count;
                            }
                        }

                    }
                    arr[callName] = obj
                    setDisposition(arr)
                    setObjD(obj)
                }
                setLoader(false)
            } catch (e) {
                console.log(e)
            }
        })()
    }, [location.search,timeEndEnd,timeStartStart])

    useEffect(() => {
        const allData = historyData?.facet_counts?.facet_fields?.status;
        const allDataDisp = historyData?.facet_counts?.facet_fields?.disposition;
        let callSatus;
        let callSatusCount;
        let pieObj = {};
        let pieObj2 = {}
        for (let i = 0; i < allData?.length; i++) {
            const dataStatus = [...allData];
            const dataCount = [...allData];
            for (let j = 0; j < allData?.length / 2; j++) {
                callSatus = dataStatus.splice(0, 2)[0]
                callSatusCount = dataCount.splice(0, 2)[1]
                if (callSatus !== null && callSatus !== "Incaming Call") {
                    pieObj[callSatus] = [callSatusCount]
                }
            }

        }
        setPie(pieObj)

        for (let i = 0; i < allDataDisp?.length; i++) {
            const dataStatus = [...allDataDisp];
            const dataCount = [...allDataDisp];
            for (let j = 0; j < allDataDisp?.length / 2; j++) {
                callSatus = dataStatus.splice(0, 2)[0]
                callSatusCount = dataCount.splice(0, 2)[1]
                if (callSatus !== null) {
                    pieObj2[callSatus] = [callSatusCount]
                }
            }

        }
        setPie2(pieObj2)
    }, [historyData])
    useEffect(() => {
        (async () => {
            try {
                let yourDate = new Date()
                const offset = yourDate.getTimezoneOffset()
                yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
                const {data} = await Api.duration({
                    endTime: query.endTime || yourDate.toISOString().split('T')[0],
                    startTime: query.startTime || yourDate.toISOString().split('T')[0],
                    number: query.number || '',
                    status: query.status || '',
                    disposition: query.disposition || '',
                    line: query.line || '',
                    direction: query.direction || '',
                    period: query.period || ''
                })
                const allData = data.duration.stats.stats_fields.duration;
                const allDataRate = data.rate.stats.stats_fields.rate;
                let filterData = {}
                let filterDataRate = {}
                let minTime = Utils.convertHMS(allData.min)
                if (Utils.convertHMS(allData.min) === "NaN:NaN:NaN") {
                    minTime = "00:00:00"
                }

                let maxTime = Utils.convertHMS(allData.max)
                if (Utils.convertHMS(allData.max) === "NaN:NaN:NaN") {
                    maxTime = "00:00:00"
                }
                let meanTime = Utils.convertHMS(allData.mean)
                if (Utils.convertHMS(allData.mean) === "NaN:NaN:NaN") {
                    meanTime = "00:00:00"
                }
                filterData["Minimum"] = allData.min;
                filterData["Maximum"] = allData.max;
                filterData["Count"] = allData.count;


                setDuration(filterData)
                setDurationAll({
                    meanTime,
                    maxTime,
                    minTime,
                    count:allData.count
                })
                filterDataRate["Minimum"] = allDataRate.min || 0;
                filterDataRate["Maximum"] = allDataRate.max || 0;
                filterDataRate["Count"] = allDataRate.count || 0;
                setRate(filterDataRate)
            } catch (e) {
                console.log(e)
            }
        })()
    }, [location.search,])
    if(loader){
        return ( <div className="row w-100">
            <h3 style={{textAlign: 'center', margin: '31px 0'}}>Loading...</h3>
        </div>)
    }else{
        return (
            <div className="row w-100">
                <h3 style={{textAlign: 'center', margin: '31px 0'}}>Calls type</h3>
                <div className="col-md-8">
                    <div className="d-flex align-items-center justify-content-center">
                        <Line
                            datasetIdKey='id'
                            data={{
                                labels: Object.keys(newObj),
                                datasets: [{
                                    data: status['Out Call'] ? Object.values(status['Out Call']) : 0,
                                    label: "Out Call",
                                    borderColor: "#3e95cd",
                                    backgroundColor: "#7bb6dd",
                                    fill: false,
                                }, {
                                    data: status['Incoming Call'] ? Object.values(status['Incoming Call']) : 0,
                                    label: "Incoming Call",
                                    borderColor: "#ffa500",
                                    backgroundColor: "#ffc04d",
                                    fill: false,
                                }, {
                                    data: status['Local Call'] ? Object.values(status['Local Call']) : 0,
                                    label: "Local Call",
                                    borderColor: "#3cba9f",
                                    backgroundColor: "#71d1bd",
                                    fill: false,
                                }, {
                                    data: status['Redirect Call'] ? Object.values(status['Redirect Call']) : 0,
                                    label: "Redirect Call",
                                    borderColor: "#c45850",
                                    backgroundColor: "#d78f89",
                                    fill: false,
                                }
                                ]
                            }}
                            type='line'
                        />
                    </div>
                </div>
                <div className="col-md-4 my_canvas">
                    <Pie
                        type="pie"
                        data={{
                            labels: Object.keys(pie),
                            datasets: [{
                                data: Object.keys(pie) ? Object.values(pie) : 0,
                                // label: Object.keys(pie),
                                fill: false,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',

                                ],
                                borderWidth: 1,
                            },
                            ],
                        }}
                    />
                </div>
                <h3 style={{textAlign: 'center', margin: '31px 0'}}>Calls status</h3>
                <div className="col-md-8">
                    <Line
                        datasetIdKey='id'
                        data={{
                            labels: Object.keys(newObjD),
                            datasets: [{
                                data: disposition['ANSWERED'] ? Object.values(disposition['ANSWERED']) : 0,
                                label: "ANSWERED",
                                borderColor: "#3e95cd",
                                backgroundColor: "#7bb6dd",
                                fill: false,
                            }, {
                                data: disposition['NO ANSWER'] ? Object.values(disposition['NO ANSWER']) : 0,
                                label: "NO ANSWER",
                                borderColor: "#ffa500",
                                backgroundColor: "#ffc04d",
                                fill: false,
                            }, {
                                data: disposition['BUSY'] ? Object.values(disposition['BUSY']) : 0,
                                label: "BUSY",
                                borderColor: "#3cba9f",
                                backgroundColor: "#71d1bd",
                                fill: false,
                            }, {
                                data: disposition['FAILED'] ? Object.values(disposition['FAILED']) : 0,
                                label: "FAILED",
                                borderColor: "#c45850",
                                backgroundColor: "#d78f89",
                                fill: false,
                            }
                            ]
                        }}
                        type='line'
                    />
                </div>
                <div className="col-md-4">
                    <Pie
                        type="pie"
                        data={{
                            labels: Object.keys(pie2),
                            datasets: [{
                                data: Object.keys(pie2) ? Object.values(pie2) : 0,
                                // label: Object.keys(pie),
                                fill: false,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',

                                ],
                                borderWidth: 1,
                            },
                            ],
                        }}
                    />
                </div>
                <h3 style={{textAlign: 'center', margin: '31px 0'}}>Calls duration</h3>
                <div className="col-md-6">
                    <Bar
                        type="bar"
                        data={{
                            labels: Object.keys(duration),
                            datasets: [{
                                data: Object.values(duration),
                                backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 205, 86)',
                                ]
                            },
                            ]
                        }}

                        options={{
                            plugins: {
                                legend: {
                                    display: false
                                }
                            },
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            }
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center flex-column justify-content-center" style={{height:200}}>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Minimum</span> - {durationAll?.minTime} </p>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Maximum</span> - {durationAll?.maxTime}</p>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Count</span> - {durationAll?.count}</p>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Average duration</span> - {durationAll?.meanTime}</p>
                    </div>
                </div>
                <h3 style={{textAlign: 'center', margin: '31px 0'}}>Calls Rating</h3>
                <div className="col-md-6">
                    <Bar
                        type="bar"
                        data={{
                            labels: Object.keys(rate),
                            datasets: [{
                                data: Object.values(rate),
                                backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 205, 86)',
                                ]
                            },
                            ]
                        }}

                        options={{
                            plugins: {
                                legend: {
                                    display: false
                                }
                            },
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            }
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center flex-column justify-content-center" style={{height:200}}>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Minimum</span> - {rate?.Minimum} </p>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Maximum</span> - {rate?.Maximum}</p>
                        <p><span style={{borderBottom: '1px solid', paddingBottom: 5}}>Count</span> - {rate?.Count}</p>
                    </div>
                </div>

            </div>
        )
            ;
    }

}

export default Graphics;
