import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '210px',
};

function TelegramDelete({open, setOpen,setForTelegram}) {
    const handleSubmit = useCallback(async () => {
        try{
            await Api.sipTelegramDelete(open)
            setOpen(0)
            setForTelegram(0)
        }catch (e) {

        }
    }, [open])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => {
                    setOpen(0)
                }}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Telegram
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen(0)
                            }}>
                                                         <CloseIcon/>

                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <p>Are you sure you want to delete your telegram number?</p>
                            <div className="d-flex justify-content-center">
                                <button style={{marginRight:10}} className="deleteBtn" onClick={handleSubmit}>
                                    Yes
                                </button>

                                <button className="addBtn" onClick={() => setOpen(0)}>
                                    No
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default TelegramDelete;
