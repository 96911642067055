import React, {useCallback, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, sip} from "../translate";
import {useDispatch} from "react-redux";
import {sipCreateRequest, sipLocalRequest} from "../store/actions/sip";
import Account from "../helpers/Account";
import Cookies from "js-cookie";
import qs from "query-string";
import {useLocation} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // height: window.innerWidth > 600?'50vh':'97vh',
    overflow: 'auto'
};

function AddSip({open, setOpen}) {
    const [form, setForm] = useState({
        count: 1,
        isMob: 1
    })
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async (ev) => {
        console.log(form)
        ev.preventDefault()
        await dispatch(sipLocalRequest(form))
        await dispatch(sipCreateRequest(form.count, Account.getUrsToken(), form.isMob, Cookies.get("sip") ||query.limit || 10, query.page || 1, query.search || '', '', query.sortBy || '', query.sortOrder || ''))
        setOpen(false)
    }, [form, location.search])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {sip.add[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    <form onSubmit={handleAdd} id="transition-modal-description sip_content" style={{marginTop: 20}}>
                        <div>
                            <button onClick={handleAdd} className="addBtnBig">
                                {sip.add_btn[+localStorage.getItem('atsLang') || 1]}
                            </button>
                        </div>
                        {Cookies.get('viewer') ?
                            <>
                                <div style={{margin: '20px 0'}}>
                                    <label className="count">
                            <span>
                               {global.count[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                        <input value={form.count}
                                               onChange={(ev) => handleChange('count', ev.target.value)} type="number"/>
                                    </label>
                                </div>
                                <div style={{margin: '44px 0px 20px'}}>
                                    <label className="row" style={{color: '#a7a7a7', fontSize: 13}}>
                                <span className="col-lg-6">
                                    {sip.num[+localStorage.getItem('atsLang') || 1]}
                                </span>
                                        <span className="col-lg-6">
                                    <input onChange={(ev) => handleChange('isMob', ev.target.checked ? 1 : 0)}
                                           checked={form.isMob} type="checkbox"/>
                                </span>
                                    </label>
                                </div>
                            </>

                            : null}

                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddSip;
