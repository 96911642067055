import React, {useEffect, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import {file, menu, moneyT,} from "../translate";
import Api from "../Api";
import MoneyChange from "./MoneyChange";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 750 ? 750 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerHeight > 600 ? 600 : '97vh',
    overflow: 'auto'
};

function Money({open, setOpen}) {
    const [money, setMoney] = useState([])
    const [number, setNumber] = useState(0)
    const [change, setChange] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.money()
                setMoney(data.mappedData)
                setNumber(data.ggumar)
            } catch (e) {

            }
        })()
    }, [])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.amount[+localStorage.getItem('atsLang')  || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            x
                        </span>
                    </div>
                    <div>
                        <table style={{width: '100%'}}>
                            <thead align="center">
                            <tr style={{padding: '10px 15px'}}
                            >
                                <th style={{textAlign: 'center',padding: '10px 15px'}}>
                                    {moneyT.name[+localStorage.getItem('atsLang')  || 1]}
                                </th>
                                <th style={{textAlign: 'center',padding: '10px 15px'}}>
                                    {moneyT.act[+localStorage.getItem('atsLang')  || 1]}
                                </th>
                                <th style={{textAlign: 'center',padding: '10px 15px'}}>
                                    {moneyT.money[+localStorage.getItem('atsLang')  || 1]}
                                </th>
                            </tr>
                            </thead>
                            <tbody align="center">
                            {money?.map((m, _) => (
                                <tr  key={_}>
                                    <td align="center" style={{padding: '10px 15px'}}>
                                        {moneyT[m.name][+localStorage.getItem('atsLang')  || 1]}
                                    </td>
                                    <td align="center" style={{padding: '10px 15px'}}>
                                        {m.act}

                                    </td>
                                    <td align="center" style={{padding: '10px 15px'}}>
                                        {m.gumar}

                                    </td>
                                </tr>
                            )) }

                            <tr>
                                <th colSpan={2} style={{padding: '10px 15px',textAlign: 'right'}} align='right'/>
                                <th style={{padding: '10px 15px', textAlign: 'center'}}>{number}</th>
                            </tr>
                            <tr>
                                <th colSpan={3} style={{textAlign: 'right'}} align='right'><hr className="m-0"/></th>
                            </tr>
                            <tr>
                                <td colSpan={3} onClick={() => setChange(true)} style={{padding: '10px 15px',textAlign: 'right', cursor:'pointer'}}>
                                    {moneyT.history[+localStorage.getItem('atsLang')  || 1]}
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                    {change?<MoneyChange open={change} setOpen={setChange}/>:null}
                </Box>
            </Fade>
        </Modal>
    );
}

export default Money;
