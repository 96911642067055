import React from 'react';
import TableCell from "@mui/material/TableCell";
import Loader from "./Loader";
import TableRow from "@mui/material/TableRow";

function TableLoader({colSpan}) {
    return (
        <TableRow><TableCell colSpan={colSpan}><div className="d-flex justify-content-center"><Loader/></div></TableCell></TableRow>
    );
}

export default TableLoader;
