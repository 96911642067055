import React, {useCallback, useEffect, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal} from "@mui/material";
import { amo} from "../translate";
import img from '../assets/img/amo.png'
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
const {REACT_APP_SERVER} = process.env;
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    overflow: 'hidden'
};

function AmoEnter({open, setOpen}) {
    const navigate = useNavigate()
    const [copy, setCopied] = useState(false)
    const [code, setCode] = useState('')
    const handleSubmit = useCallback(async (ev) => {
       setOpen('')
    }, [])
    useEffect(() => {
        if(open){
            (async () => {
                const {data} = await Api.amoKey()
                setCode(data.key)
            })()
        }
    }, [open])
    const handleCopy = useCallback(() => {
        if (navigator.clipboard) {
            setCopied(true)
            navigator.clipboard.writeText(code).catch(function () {});
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    },[code])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>

                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between">
                            <h3>{amo.header[+localStorage.getItem('atsLang')  || 1]}</h3>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen('')
                                navigate('/crm_integration')
                            }}>
                            <CloseIcon/>
                            </span>
                        </div>
                        <p className="crm_title"><span
                            className="number">1. </span><span>{REACT_APP_SERVER === 'ATS'?amo.titleA[+localStorage.getItem('atsLang')  || 1]:amo.title[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span>{amo.paragraph_p1[+localStorage.getItem('atsLang')  || 1]}
                            <a href={REACT_APP_SERVER === 'ATS'?amo.link.linkA : amo.link.link} target='_blank'>{REACT_APP_SERVER === 'ATS'?amo.link.textA[+localStorage.getItem('atsLang')  || 1]:amo.link.text[+localStorage.getItem('atsLang')  || 1]}</a>{amo.paragraph_p2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_title"><span
                            className="number">2. </span><span>{amo.title2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span>{amo.paragraph2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="d-flex align-items-center" style={{margin:10,border:'1px solid #ccc',width:'fit-content'}}>
                            <pre id="amoKey" className="m-0" style={{background: '#f2f3f7', padding: 10, margin:0}}>{code} </pre> <p onClick={handleCopy} style={{ margin: '0 20px',cursor:'pointer',fontSize:20}}><FontAwesomeIcon icon={faCopy} /></p></p>
                        <p>
                            <img src={img} alt="" style={{width:'100%'}}/>
                        </p>
                    </form>
                    <div id="transition-modal-description sip_content" className="test_mode">
                        <div className="d-flex justify-content-center">
                            <button style={{padding: '6px 17px'}} className="addBtn" onClick={handleSubmit}>
                                OK
                            </button>
                        </div>
                        {copy ? <div className="copied">Copied</div> : null}
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AmoEnter;
