export const AUTO_CALL_REQUEST = 'AUTO_CALL_REQUEST';
export const AUTO_CALL_SUCCESS = 'AUTO_CALL_SUCCESS';
export const AUTO_CALL_FAIL = 'AUTO_CALL_FAIL';

export function autoCallRequest() {
  return {
    type: AUTO_CALL_REQUEST,
    payload: { },
  };
}

export const AUTO_CALL_CREATE_REQUEST = 'AUTO_CALL_CREATE_REQUEST';
export const AUTO_CALL_CREATE_SUCCESS = 'AUTO_CALL_CREATE_SUCCESS';
export const AUTO_CALL_CREATE_FAIL = 'AUTO_CALL_CREATE_FAIL';

export function autoCallCreateRequest(data) {
  return {
    type: AUTO_CALL_CREATE_REQUEST,
    payload: {data},
  };
}

export const AUTO_CALL_DELETE_REQUEST = 'AUTO_CALL_DELETE_REQUEST';
export const AUTO_CALL_DELETE_SUCCESS = 'AUTO_CALL_DELETE_SUCCESS';
export const AUTO_CALL_DELETE_FAIL = 'AUTO_CALL_DELETE_FAIL';

export function autoCallDeleteRequest(id) {
  return {
    type: AUTO_CALL_DELETE_REQUEST,
    payload: {id},
  };
}

export const AUTO_CALL_STATUS_REQUEST = 'AUTO_CALL_STATUS_REQUEST';
export const AUTO_CALL_STATUS_SUCCESS = 'AUTO_CALL_STATUS_SUCCESS';
export const AUTO_CALL_STATUS_FAIL = 'AUTO_CALL_STATUS_FAIL';

export function autoCallStatusRequest(id,form) {
  return {
    type: AUTO_CALL_STATUS_REQUEST,
    payload: {id,form},
  };
}
export const AUTO_CALL_ADD_LOCAL = 'AUTO_CALL_ADD_LOCAL';

export function autoCallLocal(data) {
  return {
    type: AUTO_CALL_ADD_LOCAL,
    payload: { data },
  };
}
export const AUTO_CALL_DELETE_LOCAL = 'AUTO_CALL_DELETE_LOCAL';

export function autoCallDeleteLocal(id) {
  return {
    type: AUTO_CALL_DELETE_LOCAL,
    payload: { id },
  };
}



