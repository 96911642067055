import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {Helmet} from "react-helmet";
import {input, sip} from "../translate";
import Paper from "@mui/material/Paper";
import Loader from "../components/Loader";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Select from "react-select";
import {TableFooter} from "@mui/material";
import {useSelector} from "react-redux";
import Api from "../Api";
import {toast} from "react-toastify";

function CallCenter(props) {
    const [loading, setLoading] = useState(false)
    const [sip, setSip] = useState([])
    const userData = useSelector((state) => state.users.crmData)
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getCallCenter()
                setSip(data?.sip)
            } catch (e) {
            }
        })()
    }, []);


    const handleSave = useCallback(async (ev) => {
       try {
           const {data} = await Api.callCenterSipCreate({sip:ev})
           setSip((prev) => prev.map(p => {
               if(+p.buddy === +ev){
                   p.isInstalled = {id:data.crm.id,sip}
               }
               return p
           }))
           toast.success('Success')
       }catch (e) {
            toast.error('Error')
       }
    }, [])

    const handleDelete = useCallback(async (ev,sip) => {
        try {
            await Api.callCenterSipDelete({id:ev})

            toast.success('Success')
            setSip((prev) => prev.map(p => {
                if(+p.buddy === +sip){
                    p.isInstalled = {}
                }
                return p
            }))
        }catch (e) {
            toast.error('Error')

        }
    }, [])
    return (
        <>
            <Helmet>
                <title>
                    Call-Center
                </title>
            </Helmet>
            <Wrapper>
                {loading ? <div className="loaderContainer"><Loader/></div> :
                    <div className="tableParent" id="tableParent">
                        <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                            <TableContainer
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className="sip_header">
                                            <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                                Users of {userData?.callcenter?.domain}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="sip_num">
                                            <TableCell align='center'>
                                                SIP
                                            </TableCell>
                                            <TableCell align='center'>
                                                Name
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                Action
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody id="dataBody">
                                        {sip.length ? sip.map((s, _) => (
                                            <TableRow key={_} hover style={{cursor:'pointer'}}  role="checkbox" tabIndex={-1}>
                                                <TableCell align="center">
                                                    {s.buddy}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {s.notes}
                                                </TableCell>

                                                <TableCell>
                                                    <div className="d-flex justify-content-center">
                                                        {!s.isInstalled?.id ?<button onClick={() => handleSave(s.buddy)} className="addBtn">
                                                            Save
                                                        </button>:<button onClick={() => handleDelete(s.isInstalled.id,s.buddy)} className="deleteBtn">
                                                            Delete
                                                        </button>}
                                                    </div>

                                                </TableCell>
                                            </TableRow>

                                        )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                            <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                                no data

                                            </TableCell>


                                        </TableRow>
                                        }
                                        {/*    );*/}
                                        {/*})}*/}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                                <div style={{margin:10}} className="crm_save_btn"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>}
            </Wrapper>
        </>

    );
}

export default CallCenter;