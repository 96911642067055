import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import TableComponent from "../components/TableComponent";
import {did, forward, global, historyHeader, included, input, menu, short, sip} from "../translate";
import {Helmet} from "react-helmet";
import Api from "../Api";
import Cookies from 'js-cookie'
import {Link, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function BlockedIp(props) {
    const [search, setSearch] = useState('')
    const [all, setAll] = useState([])
    const navigate = useNavigate()
    const handleSubmit = useCallback(async (ev) => {
        try {
            ev.preventDefault()
            const {data} = await Api.blockedIp(search)
            setAll(data.data)
        }catch (e) {

        }
    }, [search])
    return (
       <>
           <Helmet>
               <title>
                   {menu.main[+localStorage.getItem('atsLang')  || 1 ]}
               </title>
           </Helmet>
           <Wrapper>
               <form onSubmit={handleSubmit} style={{minHeight:all.length?'50px':'100vh',width:'100%',padding:15}}>
                   <input value={search} onChange={(ev) => setSearch(ev.target.value)} placeholder={input.search[+localStorage.getItem('atsLang')  || 1 ]} className="home_input"/>
               </form>
               {all.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={3}>
                                       {menu.blockIp[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   />
                                   <TableCell align='center'>
                                       IP
                                   </TableCell>
                                   <TableCell align='center'>
                                       Date
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody id="dataBody">
                               {all?.length ? all?.map((s, _) => (
                                   <TableRow key={_} hover role="checkbox" tabIndex={-1}>
                                       <TableCell align="center">
                                           <CheckCircleIcon style={{color:color}} />
                                       </TableCell>
                                       <TableCell align="center" onClick={() => alert(`${s.buddy} ${s.sip_buddy.secret}`)}>
                                           {s.ipaddres}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s.date}
                                       </TableCell>
                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                   <TableCell align="center" colSpan={3}>
                                       no data

                                   </TableCell>


                               </TableRow>
                               }

                           </TableBody>
                       </Table>
                   </TableContainer>
               </Paper>:null}

           </Wrapper>

       </>
    );
}

export default BlockedIp;
