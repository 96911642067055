import React, {useCallback, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Api from "../Api";
import Account from "../helpers/Account";
import {global, login, sip} from "../translate";

const {REACT_APP_SERVER} = process.env;
import InputMask from "react-input-mask";
import LogRegSide from "../components/LogRegSide";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

function Drop(props) {
    const [phone, setUserName] = useState('374')
    const [password, setPassword] = useState('')
    const [newPass, setNewPass] = useState('')
    const [repeatPass, setRepeatPass] = useState('')

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [lang1, setLang] = useState(1)
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleSubmit = useCallback(async (ev) => {
        try {
            ev.preventDefault()
            if (!phone) {
                toast.error('Enter phone number');
                return;
            }

            if (newPass !== repeatPass) {
                toast.error('Passwords do not match');
                return;
            }

            if (!localStorage.getItem('drop') && !localStorage.getItem('number')) {
                setLoading(true)
                await Api.dropPass(phone.replaceAll(' ', '').replace('(', '').replace(')', ''))
                localStorage.setItem('drop', 'processing')
                localStorage.setItem('number', phone.replaceAll(' ', '').replace('(', '').replace(')', ''))
                setShow(true)
                setLoading(false)

            } else if (localStorage.getItem('drop') === 'processing' && localStorage.getItem('number')) {
                setLoading(true)

                await Api.dropPassVerify(localStorage.getItem('number'), password)
                localStorage.setItem('drop', 'verify')
                setShowPass(true)
                setLoading(false)

            } else if (localStorage.getItem('drop') === 'verify' && localStorage.getItem('number')) {
                setLoading(true)

                await Api.dropPassSuccess(localStorage.getItem('number'), newPass)
                localStorage.removeItem('drop')
                toast.success("Password changed successfully")
                navigate(`/login`)
                setLoading(false)

            } else {
                localStorage.removeItem('drop')
                localStorage.removeItem('number')
                setLoading(false)

            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e)
            localStorage.removeItem('drop')
            setShow(false)
            setLoading(false)

        }
    }, [phone, password, newPass, repeatPass, location.search])

    const handleGenerate = useCallback(() => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < 12; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            password += chars.charAt(randomIndex);
        }
        setNewPass(password)
        setRepeatPass(password)
    }, [])
    useEffect(() => {
        Account.logoutAdmin()
        localStorage.removeItem('creating')
    }, [])
    useEffect(() => {
        if (query.phone) {
            setUserName(query.phone.toString())
            setShow(true)
        }
    }, [location.search])
    const handleLangChange = useCallback((lang) => {
        setLang(lang)
        localStorage.setItem('atsLang', lang)
    }, [])
    const {REACT_APP_OPERATOR, REACT_APP_ATS} = process.env
    return (
        <>
            <Helmet>
                <title>Drop password</title>
            </Helmet>
            <div className="adminLogin row">
                <div className="loginContainer">
                    <div className="adminLoginHeader">
                        <div>
                            <div className='d-flex flex-row'>
                                <a className="loginLink"
                                   href={'/operator/login'}>Operator</a> <span className="my-border"/> <a
                                className="loginLink" href={'/login'}>Admin</a>
                            </div>
                        </div>
                        <div>
                            <span style={{cursor: 'pointer', fontSize: 13, color: "#039be5"}}
                                  onClick={() => handleLangChange(3)}>
                               Hay
                            </span>
                            <span className="my-border"/>
                            <span style={{cursor: 'pointer', fontSize: 13, color: "#039be5"}}
                                  onClick={() => handleLangChange(2)}>
                               Рус
                            </span>
                            <span className="my-border"/>
                            <span style={{cursor: 'pointer', fontSize: 13, color: "#039be5"}}
                                  onClick={() => handleLangChange(1)}>
                               Eng
                            </span>
                        </div>
                    </div>
                    <div className="adminLoginPage">

                        <form onSubmit={handleSubmit} className="adminLoginForm">

                            <div className="adminLoginMain">
                                {!showPass ? <label
                                    className="adminInput"
                                ><InputMask
                                    mask="374 (99) 999999"
                                    maskChar="_"
                                    placeholder="374 (__) ______"
                                    onChange={(ev) => setUserName(ev.target.value)}
                                    value={phone}
                                >
                                    {(inputProps) => <input {...inputProps} />}
                                </InputMask>
                                </label> : null}
                                {show && !showPass ?
                                    <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                        <input
                                            id="adminPass"
                                            value={password}
                                            placeholder={login.passR[lang1]}
                                            type={show ? 'text' : 'password'}
                                            onChange={(ev) => setPassword(ev.target.value)}
                                        />
                                    </label> : null}

                                {showPass ?
                                    <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                        <input
                                            id="adminPass1"
                                            value={newPass}
                                            placeholder={login.passR[lang1]}
                                            type={'text'}
                                            onChange={(ev) => setNewPass(ev.target.value)}
                                        />
                                    </label> : null}
                                {showPass ?
                                    <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                        <input
                                            id="adminPass2"
                                            value={repeatPass}
                                            placeholder={login.passRe[lang1]}
                                            type={'text'}
                                            onChange={(ev) => setRepeatPass(ev.target.value)}
                                        />
                                    </label> : null}
                                <div className="d-flex align-items-center">
                                    {showPass ?
                                        <div className="adminLoginBtn" onClick={handleGenerate}
                                             style={{marginRight: 19}}>
                                            {sip.generate[+localStorage.getItem('atsLang') || 1]}
                                        </div>
                                        : null}
                                    <button type="submit" onClick={handleSubmit}
                                            className="adminLoginBtn">{loading?"Loading...":global.accept[lang1]}</button>
                                </div>

                                <span><Link to="/login">{login.goBack[lang1]}</Link></span>

                            </div>
                        </form>
                    </div>
                </div>
                {REACT_APP_SERVER === 'Beeline' ? null : <LogRegSide/>
                }
            </div>
        </>

    );
}

export default Drop;
