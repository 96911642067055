import React, { useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import { useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {profileRequestLocal} from "../store/actions/users";
import {toast} from "react-toastify";
import Api from "../Api";
import Account from "../helpers/Account";
import {lang, login} from "../translate";
import Cookies from "js-cookie";
import img from '../assets/img/logo22.png'
import uc from '../assets/img/image_ucom.png'
const {REACT_APP_SERVER} = process.env;
import ats from '../assets/img/logo_light.png'

function Confirm(props) {
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() =>{
        if(REACT_APP_SERVER === 'ATS'){
            document.body.style.backgroundImage = 'none'
            if(document.getElementsByTagName('header')[0]){
                document.getElementsByTagName('header')[0].style.backgroundImage = 'none'
            }
            if(document.getElementsByTagName('footer')[0]){
                document.getElementsByTagName('footer')[0].style.backgroundImage = 'none'
            }
        }

    },[REACT_APP_SERVER])
    useEffect(() => {
        (async () => {
            try {
                if (password.length === 6) {
                    let newPhone = params.phone.replaceAll(' ','').replace('(','').replace(')','')

                    const {data} = await Api.confirm({phone:newPhone, password, lang: lang[+localStorage.getItem('atsLang')],type:params.type})
                    if(params.type === 'user'){
                        await dispatch(profileRequestLocal(data))
                        Account.setAdminToken(data)
                        navigate('/')
                    }else{
                        await dispatch(profileRequestLocal(data.user))
                        Account.setUrsAdminToken(data)
                        navigate('/urs')
                    }
                    Cookies.remove('login')
                }

            } catch (e) {
                toast.error(e.response.data.message);
                navigate('/login')
            }
        })()
    },[password,params])

    const {REACT_APP_OPERATOR, REACT_APP_ATS} = process.env
    return (
        <>
            <Helmet>
                <title>{login.title[+localStorage.getItem('atsLang')]}</title>
            </Helmet>
            <div className="adminLogin flex-column">
                <section className="form_login_header">
                    {REACT_APP_SERVER === "ATS"?
                        <img style={{width: 110}} src={ats} alt=""/>
                        :<div style={REACT_APP_SERVER === "V-office" ||REACT_APP_SERVER === "Ucom"?{backgroundImage:"url("+uc+")"}:{backgroundImage:"url("+img+")",backgroundSize:100, backgroundPosition:'center'}} className="form_login_header_logo"/>}
                    <div style={REACT_APP_SERVER === "ATS"?{color:'#00AB64'}:{}} className="form_login_header_info">
                        Virtual ATS
                    </div>
                </section>
                <div className="confirmContainer">
                    <div className="adminLoginPage">

                        <form className="adminLoginForm">

                            <div className="adminLoginMain">
                                <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                    <input
                                        autoFocus={true}
                                        id="adminPass"
                                        value={password}
                                        onChange={(ev) => setPassword(ev.target.value)}
                                    />
                                    <span className="count_pass">{password.length} / 6</span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Confirm;
