import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import {Helmet} from "react-helmet";
import {input, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {TableFooter} from "@mui/material";
import {toast} from "react-toastify";
import Select from "react-select";
import TableLoader from "../components/TableLoader";
import {userCrmRequest} from "../store/actions/users";

const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'
let newArr = []
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
function Pipedrive(props) {
    const userData = useSelector((state) => state.users.crmData)

    const [pipedrive,setPipedrive] = useState([])
    const [loader,setLoader] = useState(false)
    const [single,setSingle] = useState(0)
    const [sipNum, setSip] = useState([]);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            try{
                setLoader(true)
                let {data} = await Api.pipedriveUsers()
                setPipedrive(data.data)
                newArr = data.data.map(u => ({userid: u.id, sip: u.sip || ''}))
                setLoader(false)
            }catch (e) {
                navigate('/crm_integration')
            }
        })()
    }, [])

    const handleSipSelect = useCallback((sip, id) => {
        newArr.map(u => {
            if (u.userid === id) {
                u.sip = sip
            }
        })
        setPipedrive(prev =>
            prev.map(u => {
                if (u.id === id) {
                    return {...u, sip: sip};
                }
                return u;
            })
        );
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSip(data.inputNumber?.sip)
            } catch (e) {
                console.log(e)
            }
        })()
    }, []);
    useEffect(() => {
        (async () => {
            await dispatch(userCrmRequest())
        })()
    }, [])
    const handleSave = useCallback(async (item) => {
        try {
            let f = newArr.find((s) => s.userid === item.id)
            if (item.sip) {
                if (item.crm_id) {
                    await Api.pipedriveCrmEdit({...f, id: item.crm_id})
                    let {data} = await Api.pipedriveUsers()
                    setPipedrive(data.data)
                    newArr = data.data.map(u => ({userid: u.id, sip: u.sip || ''}))
                    toast.success("Successfully edited")
                } else {
                    await Api.pipedriveCrmCreate({...f, domain: userData?.pipedrive?.domain.split('.')[0]})
                    let {data} = await Api.pipedriveUsers()
                    setPipedrive(data.data)
                    newArr = data.data.map(u => ({userid: u.id, sip: u.sip || ''}))
                    toast.success("Successfully created")
                }
                setSingle(item.sip)

            } else if (item.crm_id) {
                await Api.pipedriveCrmDelete(item.crm_id)
                let {data} = await Api.pipedriveUsers()
                setPipedrive(data.data)
                newArr = data.data.map(u => ({userid: u.id, sip: u.sip || ''}))
                toast.success("Successfully deleted")

            }
        } catch (e) {

        }
    }, [newArr, userData])
    return (
        <>
            <Helmet>
                <title>
                    {sip.crm[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent" id="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                            Users of Pipedrive - {userData?.pipedrive?.domain}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell align='center'>
                                            ID
                                        </TableCell>
                                        <TableCell align='center'>
                                            Full name
                                        </TableCell>
                                        <TableCell align='center'>
                                            Email
                                        </TableCell>
                                        <TableCell align='center'>
                                            Phone
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Selected SIP
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Action
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody id="dataBody">
                                    {loader?<TableLoader colSpan={8}/>:pipedrive?.length ? pipedrive?.map((s, _) => (
                                        <TableRow key={_} hover style={{cursor: 'pointer'}} role="checkbox" tabIndex={-1}>
                                            <TableCell align="center" onClick={() => setSingle(s.sip)}>
                                                {s?.rights?.is_active ? <CheckCircleIcon style={{color: color}}/> : null}
                                            </TableCell>
                                            <TableCell onClick={() => setSingle(s.sip)} align="center">
                                                {s?.id}
                                            </TableCell>
                                            <TableCell onClick={() => setSingle(s.sip)} align="center" >
                                                {s.name}
                                            </TableCell>
                                            <TableCell onClick={() => setSingle(s.sip)} align="center" >
                                                {s.email}
                                            </TableCell>
                                            <TableCell onClick={() => setSingle(s.sip)} align="center" >
                                                {s.phone}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => setSingle(s.sip)}>
                                                {s?.is_admin ? 'Admin' : ''}
                                            </TableCell>
                                            <TableCell align="center" style={{width: 160}}>
                                                <Select
                                                    styles={customStyles}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    onChange={(ev) => handleSipSelect(ev.value, s.id,)}
                                                    menuPortalTarget={document.body}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    value={{label: s.sip, value: s.sip}}
                                                    name="color"
                                                    options={[{number: '', label: 'Select value'}, ...sipNum.map(k => (
                                                        {value: +k.number, label: `${k.number} ${k.context || ''}`}
                                                    ))]}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <button className="addBtn" onClick={() => handleSave(s)}>
                                                    Save
                                                </button>
                                            </TableCell>
                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            no data

                                        </TableCell>


                                    </TableRow>
                                    }

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            <div style={{margin: 10}} className="crm_save_btn"/>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>

            </Wrapper>
        </>

    );
}

export default Pipedrive;
