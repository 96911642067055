import React from 'react';
import ats from "../assets/img/footer_logo.png";
import Ucom from "../assets/img/Ucom.jpg";
import {login} from "../translate";
import tel from "../assets/img/telegram-logo.png";
import app from "../assets/img/playstore.png";
import apple from "../assets/img/apple-icon.png";
const {REACT_APP_SERVER} = process.env;

function LogRegSide(props) {
    return (
        <div className="loginAside">
            <figure  className="logoLogin">
                <img style={REACT_APP_SERVER === 'ATS'?{width: '100%',objectFit:'scale-down'}:{width: '100%', objectFit: 'cover'}} src={REACT_APP_SERVER === 'ATS'?ats:Ucom} alt=""/>
            </figure>
            <div>
                <p className="contact">
                    {login.contact[+localStorage.getItem('atsLang') || 1]}
                </p>
                <a href="mailto:info@ats.am">Email: info@ats.am</a>
                <p className="d-flex align-items-center justify-content-center"><a href="https://t.me/it_spark"><img style={{width: 30, height: 30}} src={tel} alt=""/> </a><a href="tel:+37444720101"> +37444720101</a>,<a
                    href="tel:+37411720101"> +37411720101</a></p>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <a style={{color: 'white'}}
                       href="https://play.google.com/store/apps/details?id=am.ats.ipats_client&pcampaignid=web_share">
                        <figure className="app_img_figure">
                            <img className="app_img"
                                 src={app} alt=""/>
                            <figcaption>Get it on Play Market</figcaption>
                        </figure>
                    </a>
                    <a style={{color: 'white'}} href="https://apps.apple.com/am/app/ipats-client/id6468366668">
                        <figure className="app_img_figure">
                            <img className="app_img"
                                 src={apple}
                                 alt=""/>
                            <figcaption>Get it on Play Store</figcaption>
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default LogRegSide;
