import {
    CALL_ROUT_ADD_LOCAL, CALL_ROUT_CREATE_SUCCESS,
    CALL_ROUT_DELETE_LOCAL,
    CALL_ROUT_EDIT_SUCCESS,
    CALL_ROUT_FAIL,
    CALL_ROUT_SUCCESS,
} from '../actions/callRout';

const initialState = {
    callRoutStatus: '',
    callRoutData: [],
    page: 1,
    count: 0,
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CALL_ROUT_SUCCESS: {
            return {
                ...state,
                callRoutStatus: 'ok',
                callRoutData: action.payload.data.routs,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        } case CALL_ROUT_CREATE_SUCCESS: {
            return {
                ...state,
                callRoutStatus: 'ok',
                callRoutData: action.payload.data.routs,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case CALL_ROUT_EDIT_SUCCESS: {
            return {
                ...state,
                callRoutStatus: 'ok',
                callRoutData: action.payload.data.routs,
                page: action.payload.data.page,
                count: action.payload.data.count,
            };
        }
        case CALL_ROUT_FAIL: {
            return {
                ...state,
                callRoutStatus: 'fail',
            };
        }
        case CALL_ROUT_ADD_LOCAL: {
            return {
                ...state,
                callRoutStatus: 'fail',
                callRoutData: [...state.callRoutData, {...action.payload.data, id: new Date()},]
            };
        }
        case CALL_ROUT_DELETE_LOCAL: {
            return {
                ...state,
                callRoutStatus: 'fail',
                callRoutData: state.callRoutData.filter(c => c.id !== action.payload.id)
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
