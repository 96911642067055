import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import Loader from "../components/Loader";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {input} from "../translate";
import {TableFooter} from "@mui/material";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import Select from "react-select";

const {REACT_APP_SERVER} = process.env;

let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
let newArr = []
function Zoho(props) {
    const [zoho, setZoho] = useState([]);
    const [sipNum, setSip] = useState([]);
    const handleSipSelect = useCallback((sip, id) => {
        newArr.map(u => {
            if (u.userid === id) {
                u.sip = sip
            }
        })
        setZoho(prev =>
            prev.map(u => {
                if (u.zuid === id) {
                    return {...u, sip: sip};
                }
                return u;
            })
        );
    }, [])

    const handleSave = useCallback(async (item) => {
        try {
            let f = newArr.find((s) => s.userid === item.zuid)
            if (item.sip) {
                if (item.crm_id) {
                    await Api.zohoCrmEdit({...f, id: item.crm_id})
                    const {data} = await Api.getZohoUsers(10, 50);
                    setZoho(data)
                    newArr = data.map(u => ({userid: u.zuid, sip: u.sip || ''}))
                    toast.success("Successfully edited")
                } else {
                    await Api.zohoCrmCreate({...f})
                    const {data} = await Api.getZohoUsers(10, 50);
                    setZoho(data)
                    newArr = data.map(u => ({userid: u.zuid, sip: u.sip || ''}))
                    toast.success("Successfully created")
                }
            } else if (item.crm_id) {
                await Api.zohoCrmDelete(item.crm_id)
                const {data} = await Api.getZohoUsers(10, 50);
                setZoho(data)
                newArr = data.map(u => ({userid: u.zuid, sip: u.sip || ''}))
                toast.success("Successfully deleted")
            }
        } catch (e) {
            console.log(e)
        }
    }, [newArr,])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        (async () => {
            const {data} = await Api.getZohoUsers(10, 50);
            setZoho(data)
            newArr = data.map(u => ({userid: u.zuid, sip: u.sip || ''}))
            const new_data = await Api.getAllowed()
            setSip(new_data.data.inputNumber?.sip)
        })()
    }, [])

    return (
        <Wrapper>
            {loading ? <div className="loaderContainer"><Loader/></div> :
                <div className="tableParent" id="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                            Users of Zoho
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell align='center'>
                                            ID
                                        </TableCell>
                                        <TableCell align='center'>
                                            Full name
                                        </TableCell>
                                        <TableCell align='center'>
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Register date
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Selected SIP
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Action
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody id="dataBody">
                                    {zoho.length ? zoho.map((s, _) => (
                                        <TableRow key={_} hover style={{cursor: 'pointer'}} role="checkbox"
                                                  tabIndex={-1}>
                                            <TableCell align="center" onClick={() => null}>
                                                {s?.status === 'active' ?
                                                    <CheckCircleIcon style={{color: color}}/> : null}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.zuid}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {s.full_name}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {s.email}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {s.type__s}
                                            </TableCell>
                                            <TableCell align="center" onClick={() => null}>
                                                {new Date(s.created_time).toDateString()}
                                            </TableCell>
                                            <TableCell align="center" style={{width: 160}}>
                                                <Select
                                                    styles={customStyles}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    onChange={(ev) => handleSipSelect(ev.value, s.zuid,)}
                                                    menuPortalTarget={document.body}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    value={{label: s.sip, value: s.sip}}
                                                    name="color"
                                                    options={[{number: '', label: 'Select value'}, ...sipNum.map(k => (
                                                        {value: +k.number, label: `${k.number} ${k.context || ''}`}
                                                    ))]}
                                                />
                                            </TableCell>

                                            <TableCell >
                                                <button onClick={() => handleSave(s)} className="addBtn">
                                                    Save
                                                </button>
                                            </TableCell>
                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            no data

                                        </TableCell>


                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            <div style={{margin: 10}} className="crm_save_btn"/>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>}
        </Wrapper>
    );
}

export default Zoho;
