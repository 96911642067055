import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {Helmet} from "react-helmet";
import {auto, file, global, input, ivr, menu, short, sip, weekDays} from "../translate";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/material";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";
import Flatpickr from "react-flatpickr";
import {toast} from "react-toastify";

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto'
};

function AutoCallUpload(props) {
    const [inAuto, setIn] = useState([])
    const navigate = useNavigate()
    const params = useParams()
    const [form,setForm] = useState({
        num:'',
        weekStart: weekDays[1][+localStorage.getItem('atsLang') || 1],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang') || 1],
        timeStart: '10:00',
        timeEnd: '22:59',
        attempt: 2,
        okStart: 0,
        okEnd: 60,
    })
    const handleAddFile = useCallback(async (ev) => {
        try {
            console.log(ev,params.id,form)
            await Api.autoCallUpload(ev,params.id,form)
            navigate(`/auto_call/${params.id}`)
            toast.success('Uploaded successfully')
        }catch (e) {
            toast.error('Error happened ' + e?.response?.data?.message)
        }
    },[params,form])
    const handleTimeChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleTime = (name, value) => {
        const input = value;
        if (/^([0-9]{0,2}):?([0-9]{0,2})?$/.test(input) || input === '') {
            setForm((prevState) => ({
                ...prevState,
                [name]: input,
            }))
        }
    };
    const handleBlur = (name) => {
        if (form[name]) {
            const [hours, minutes] = form[name].split(':').map(Number);
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            setForm((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        }
    };
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        try {
            navigate('/auto_call')
        } catch (e) {

        }
    }, [ params])

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.singleAuto(params.id)
                setIn(data.memberGroups)
            } catch (e) {
                console.log(e)
            }
        })()
    }, [params.id])
    return (
        <>
            <Helmet>
                <title>
                    {menu.autoCall[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <p style={{
                                color: '#a7a7a7',
                                fontSize: 13,
                                marginBottom: 13, marginLeft: 13,
                            }}>
                            </p>
                            <Link style={{cursor: 'pointer'}} to="/auto_call">
                                <CloseIcon/>

                            </Link>
                        </div>
                        <form onSubmit={handleAdd}>
                            <div className="short_block" style={{margin: '30px 0',}}>
                                <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                                    <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                                        <div style={{width: 150}}>
                                            {auto.num[+localStorage.getItem('atsLang')  || 1]}
                                        </div>
                                        <div style={{width: 170}}>
                                            {short.week[+localStorage.getItem('atsLang')  || 1]}
                                        </div>
                                        <div style={{width: 170}}>
                                            {short.time[+localStorage.getItem('atsLang')  || 1]}
                                        </div>
                                        <div style={{width: 120}}>
                                            {auto.ok[+localStorage.getItem('atsLang')  || 1]}
                                        </div>

                                        <div style={{width: 70}}>
                                            {auto.attempt[+localStorage.getItem('atsLang')  || 1]}
                                        </div>

                                    </div>

                                </div>
                                <div className="d-flex flex-row text-center"
                                     style={{width: 'fit-content', margin: '10px 0'}}>

                                    <div style={{width: 150}}>
                                        <select value={form.num}
                                                onChange={(ev) => handleTimeChange('num', ev.target.value)}
                                                style={{width: '100%', height: 40}}>
                                            <option
                                                value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                            {inAuto.map(k => (
                                                <option key={k.azang} value={k.azang}>{k.azang} ({k.comment})</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{width: 170}}>
                                        <select value={form.weekStart}
                                                onChange={(ev) => handleTimeChange('weekStart', ev.target.value)}
                                                style={{width: '40%', height: 40}}>
                                            <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                            {weekDays.map(k => (
                                                <option key={k[+localStorage.getItem('atsLang')  || 1]}
                                                        value={k[+localStorage.getItem('atsLang')  || 1]}>{k[+localStorage.getItem('atsLang')  || 1]}</option>
                                            ))}
                                        </select>
                                        <span>-</span>
                                        <select value={form.weekEnd}
                                                onChange={(ev) => handleTimeChange('weekEnd', ev.target.value)}
                                                style={{width: '40%', height: 40}}>
                                            <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                            {weekDays.map(k => (
                                                <option key={k[+localStorage.getItem('atsLang')  || 1]}
                                                        value={k[+localStorage.getItem('atsLang')  || 1]}>{k[+localStorage.getItem('atsLang')  || 1]}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{width: 170}}>
                                        <input
                                            type="text"
                                            value={form.timeStart}
                                            onChange={(ev) => handleTime('timeStart',ev.target.value)}
                                            onBlur={() => handleBlur('timeStart')}
                                            placeholder="HH:mm"
                                            style={{
                                                width: '40%',
                                                textAlign: 'center',
                                                border: '1px solid',
                                                height: 35,
                                                fontSize: 14
                                            }}
                                        />
                                        <span>-</span>
                                        <input
                                            type="text"
                                            value={form.timeEnd}
                                            onChange={(ev) => handleTime('timeEnd',ev.target.value)}
                                            onBlur={() => handleBlur('timeEnd')}
                                            placeholder="HH:mm"
                                            style={{
                                                width: '40%',
                                                textAlign: 'center',
                                                border: '1px solid',
                                                height: 35,
                                                fontSize: 14
                                            }}
                                        />

                                    </div>
                                    <div style={{width: 120}}>
                                        <input
                                            className="ok"
                                            value={form.okStart}
                                            onChange={(ev) => handleTimeChange('okStart', ev.target.value)}
                                            style={{width: '40%', height: 40}}/>
                                        <span>-</span>
                                        <input
                                            className="ok"
                                            value={form.okEnd}
                                            onChange={(ev) => handleTimeChange('okEnd', ev.target.value)}
                                            style={{width: '40%', height: 40}}/>
                                    </div>
                                    <div style={{width: 70}}>
                                        <input className="ok"
                                               onChange={(ev) => handleTimeChange('attempt', ev.target.value)}
                                               value={form.attempt} style={{width: '90%', height: 40}}/>
                                    </div>

                                </div>
                            </div>

                            <div className="d-flex justify-content-center">

                                <label className="addBtnBig d-flex align-items-center" style={{
                                    background: '#555',
                                    marginTop: '10px',
                                    width:'150px',
                                    height:'60px',
                                }}>
                                    <span className="forFile">{file.choose[+localStorage.getItem('atsLang')  || 1]}</span>
                                    <input onChange={(ev) => handleAddFile(ev.target.files[0])} className="ok" style={{display: 'none'}} type="file"/>
                                </label>

                            </div>
                            <div className="d-flex justify-content-end" style={{marginTop: 30}}>

                                {/*<button onClick={handleAdd} className="addBtnBig">*/}
                                {/*    {global.edit[+localStorage.getItem('atsLang')  || 1]}*/}
                                {/*</button>*/}

                            </div>
                        </form>
                    </Box>

                </div>

            </Wrapper>
        </>

    );
}

export default AutoCallUpload;
