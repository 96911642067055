export const CRM_REQUEST = 'CRM_REQUEST';
export const CRM_SUCCESS = 'CRM_SUCCESS';
export const CRM_FAIL = 'CRM_FAIL';

export function crmRequest() {
    return {
        type: CRM_REQUEST,
        payload: {},
    };
}

export const CRM_SINGLE_REQUEST = 'CRM_SINGLE_REQUEST';
export const CRM_SINGLE_SUCCESS = 'CRM_SINGLE_SUCCESS';
export const CRM_SINGLE_FAIL = 'CRM_SINGLE_FAIL';

export function crmSingleRequest(id) {
    return {
        type: CRM_SINGLE_REQUEST,
        payload: {id},
    };
}




export const CRM_CREATE_REQUEST = 'CRM_CREATE_REQUEST';
export const CRM_CREATE_SUCCESS = 'CRM_CREATE_SUCCESS';
export const CRM_CREATE_FAIL = 'CRM_CREATE_FAIL';

export function crmCreateRequest(form) {
    return {
        type: CRM_CREATE_REQUEST,
        payload: {form},
    };
}





