import React, {useCallback, useEffect} from 'react';
import {included, input, short, sip} from "../translate";
import Api from "../Api";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
function IncludeNumberBlock({form,handleChange, handleWheel, handleGenerate,index , isMultiple,setMultiple,multiple}) {
    let handleDelete = useCallback(() => {
        setMultiple(prev => prev.filter((_, i) => i !== index));
    }, [index])
    let handleChangeSingle = useCallback((key, value) => {
        if(isMultiple) {
            let newMultiple = [...multiple];
            newMultiple[index][key] = value;
            setMultiple(newMultiple);
        }
    },[isMultiple,multiple,index]);
    const handleGenerateSingle = useCallback(async (isShort) => {
        if (isShort) {
            const maxShortElement = multiple.reduce((max, obj) => (obj.short > max.short ? obj : max), multiple[0]);
            if(maxShortElement.short){
                const {data} = await Api.generate(+maxShortElement.short+1)
                handleChangeSingle('short', data.counter)
            }
            else{
                const {data} = await Api.generate(11)
                handleChangeSingle('short', data.counter)
            }
        }
    }, [form])
    return (
        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <label className="count" style={{margin: '5px 0',width:270}}>
                                        <span>
                                            {included.phone[+localStorage.getItem('atsLang') || 1]} :
                                        </span>
                    <input type="number" value={form.phone}
                           onChange={(ev) => isMultiple?handleChangeSingle('phone', ev.target.value) : handleChange('phone', ev.target.value)}/>
                </label>
                <div className="count" style={{margin: '5px 0',width:220}}>
                    <div className="d-flex justify-content-between align-items-center">
                                            <span className="m-0">{short.shorts[+localStorage.getItem('atsLang') || 1]} :
                                                <input type="checkbox"
                                                       style={{width: 13, height: 13, marginLeft: 10}}
                                                       checked={form.isShort}
                                                       onWheel={handleWheel}
                                                       onChange={(ev) => isMultiple?handleChangeSingle('isShort', ev.target.value) : handleChange('isShort', ev.target.checked)}/>
                                            </span>
                        <div style={form.isShort ? {} : {background: '#ccc'}}
                             className="adminLoginBtn m-0"
                             onClick={() => isMultiple?handleGenerateSingle(form.isShort):handleGenerate(form.isShort)}>
                            {sip.generate[+localStorage.getItem('atsLang') || 1]}
                        </div>
                    </div>

                    <input type="number"
                           disabled={!form.isShort}
                           value={form.short}
                           onChange={(ev) => handleChange('short', ev.target.value)}
                    />
                </div>
                {isMultiple && multiple.length > 1 ?<RemoveCircleIcon onClick={handleDelete} style={{color:'red',cursor:"pointer"}}/>:null}
            </div>
        </div>
    );
}

export default IncludeNumberBlock;