import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackward,
    faBackwardFast,
    faCirclePlay,
    faForward,
    faForwardFast,
    faStop
} from "@fortawesome/free-solid-svg-icons";
const {REACT_APP_SERVER} = process.env;

let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
function Controls({ audioRef, progressBarRef, duration, setTimeProgress,track, trackIndexSet, setTrackIndex, setCurrentTrack,interacted }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(60);
    const togglePlayPause = useCallback(() => {
        setIsPlaying((prev) => !prev);
    },[]);
    useEffect(() => {
        if (interacted){
            setIsPlaying((prev) => !prev);
        }
    },[interacted]);
    const playAnimationRef = useRef();
    const repeat = useCallback(() => {
        try{
            const currentTime = audioRef.current.currentTime;
            setTimeProgress(currentTime);
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
                '--range-progress',
                `${(progressBarRef.current.value / duration) * 100}%`
            );

            playAnimationRef.current = requestAnimationFrame(repeat);
        }catch (e) {
            return null
        }
    }, [audioRef, duration, progressBarRef, setTimeProgress]);

    useEffect(() => {
       try {
           if (isPlaying) {
               audioRef.current.play();
           } else {
               audioRef.current.pause();
           }
           playAnimationRef.current = requestAnimationFrame(repeat);
       }catch {
           return null
       }
    }, [isPlaying, audioRef, repeat]);

    const skipForward = () => {
        audioRef.current.currentTime += 15;
    };

    const skipBackward = () => {
        audioRef.current.currentTime -= 15;
    };

    const handlePrevious = () => {
        if (trackIndexSet === 0) {
            let lastTrackIndex = track.length - 1;
            setTrackIndex(lastTrackIndex);
            setCurrentTrack(track[lastTrackIndex]);
        } else {
            setTrackIndex((prev) => prev - 1);
            setCurrentTrack(track[trackIndexSet - 1]);
        }
    };

    const handleNext = () => {
        if (trackIndexSet >= track.length - 1) {
            setTrackIndex(0);
            setCurrentTrack(track[0]);
        } else {
            setTrackIndex((prev) => prev + 1);
            setCurrentTrack(track[trackIndexSet + 1]);
        }
    };
    useEffect(() => {
        if (audioRef) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume, audioRef]);
    return (
        <div className="controls-wrapper">
            <div className="controls">
                <button onClick={handlePrevious}>
                    <FontAwesomeIcon icon={faBackwardFast} />
                </button>
                <button onClick={skipBackward}>
                    <FontAwesomeIcon icon={faBackward} />
                </button>

                <button onClick={togglePlayPause}>
                    {isPlaying ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faCirclePlay} />}
                </button>
                <button onClick={skipForward}>
                    <FontAwesomeIcon icon={faForward} />
                </button>
                <button onClick={handleNext}>
                    <FontAwesomeIcon icon={faForwardFast} />
                </button>
            </div>
            <div className="volume">
                <button>icons</button>
                <input style={{
                    background: `linear-gradient(to right, ${color} ${volume}%, #ccc ${volume}%)`,
                }} type="range" min={0} max={100} value={volume}
                       onChange={(ev) => setVolume(+ev.target.value)} />
            </div>
        </div>
    );
}

export default Controls;
