import { takeLatest, call, put } from 'redux-saga/effects';
import {
    SIP_DELETE_SUCCESS,
    SIP_DELETE_REQUEST,
    SIP_DELETE_FAIL,
    SIP_EDIT_FAIL,
    SIP_EDIT_REQUEST,
    SIP_EDIT_SUCCESS,
    SIP_REQUEST,
    SIP_SUCCESS,
    SIP_FAIL,
    SIP_CREATE_SUCCESS,
    SIP_CREATE_FAIL,
    SIP_CREATE_REQUEST,
    SIP_SINGLE_REQUEST,
    SIP_SINGLE_SUCCESS,
    SIP_SINGLE_FAIL,
    SIP_PROVIDER_SUCCESS,
    SIP_PROVIDER_FAIL,
    SIP_PROVIDER_REQUEST,
    SIP_PROVIDER_CREATE_SUCCESS,
    SIP_PROVIDER_CREATE_FAIL,
    SIP_PROVIDER_CREATE_REQUEST,
    SIP_PROVIDER_EDIT_FAIL,
    SIP_PROVIDER_EDIT_SUCCESS, SIP_PROVIDER_EDIT_REQUEST
} from '../actions/sip';
import Api from '../../Api';
import {toast} from "react-toastify";
import {lang} from "../../translate";

function* handleSip(action) {
    try {
        const {limit, page, search, sortBy,sortOrder,link} = action.payload
        const { data } = yield call(Api.sip, limit,page,search,sortBy,sortOrder,link);
        yield put({
            type: SIP_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleSipProvider(action) {
    try {
        const {limit, page, search} = action.payload
        const { data } = yield call(Api.getProvider,search,limit,page);
        yield put({
            type: SIP_PROVIDER_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_PROVIDER_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleSipSingle(action) {
    try {
        const {phone} = action.payload
        const { data } = yield call(Api.sipSingle, phone);
        yield put({
            type: SIP_SINGLE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_SINGLE_FAIL,
            payload: { error: e.response },
        });
    }
}


function* handleSipEdit(action) {
    try {
        const { id, formData,limit, page, search,link,sort,sortBy } = action.payload;
        const {data} = yield call(Api.sipEdit,id, formData,limit, page, search,link,lang[+localStorage.getItem('atsLang')],sort,sortBy);
        toast.success(lang[+localStorage.getItem('atsLang')] === 'en-AU' ? 'Sip is successfully edited' : lang[+localStorage.getItem('atsLang')] === 'ru' ? 'Sip успешно обновлен' : 'Sip-ը հաջողությամբ թարմացվել է')

        yield put({
            type: SIP_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: SIP_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleSipCreate(action) {
    try {
        const {buddy,token,issip,limit,page,search,lang,sort, sortBy} = action.payload;
        const {data} = yield call(Api.sipCreate,buddy,token,issip,limit,page,search,lang,sort, sortBy);

        toast.success('Sip is successfully created')

        yield put({
            type: SIP_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleSipProviderCreate(action) {
    try {
        const {form,limit,page} = action.payload;
        const {data} = yield call(Api.createProvider,form,limit,page);

        toast.success('Sip provider is successfully created')

        yield put({
            type: SIP_PROVIDER_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_PROVIDER_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleSipProviderEdit(action) {
    try {
        const {form,limit,page} = action.payload;
        const {data} = yield call(Api.editProvider,form,limit,page);

        toast.success('Sip provider is successfully edited')

        yield put({
            type: SIP_PROVIDER_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_PROVIDER_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleSipDelete(action) {
    try {
        const { form,link } = action.payload;
        const {data} = yield call(Api.sipDelete,form,link);
        toast.success('Sip is successfully deleted')

        yield put({
            type: SIP_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: SIP_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(SIP_REQUEST, handleSip);
    yield takeLatest(SIP_PROVIDER_REQUEST, handleSipProvider);
    yield takeLatest(SIP_PROVIDER_CREATE_REQUEST, handleSipProviderCreate);
    yield takeLatest(SIP_PROVIDER_EDIT_REQUEST, handleSipProviderEdit);
    yield takeLatest(SIP_CREATE_REQUEST, handleSipCreate);
    yield takeLatest(SIP_EDIT_REQUEST, handleSipEdit);
    yield takeLatest(SIP_DELETE_REQUEST, handleSipDelete);
    yield takeLatest(SIP_SINGLE_REQUEST, handleSipSingle);
}
