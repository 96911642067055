import React from 'react';
import TableCell from "@mui/material/TableCell";
import IconForCall from "./IconForCall";
import {history1} from "../translate";
import Utils from "../Utils";
import {Tooltip} from "react-tooltip";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import Cookies from "js-cookie";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TableRow from "@mui/material/TableRow";
import {useSelector} from "react-redux";
import Loader from "./Loader";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

function HistoryLine({r,counter,handleAudio,handleText,background="#fff",isOpen,setIsOpen,loading}) {
    const profile = useSelector(state => state.users.ursData)

    return (
        <TableRow
            style={{cursor: 'pointer', background: background,}}
            className="sip_num" hover role="checkbox" tabIndex={-1}
            >

            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                align="center">
                {REACT_APP_SERVER === 'Ucom' ? '' : counter}
                {REACT_APP_SERVER === 'Ucom' ? r.disposition !== "ANSWERED" ?
                    <IconForCall color={history1.callType[1].color}
                                 path={history1.callType[1].path}/> : r.ext_num ?
                        <IconForCall color={history1.callType[2].color}
                                     path={history1.callType[2].path}/> :
                        <IconForCall color={history1.callType[0].color}
                                     path={history1.callType[0].path}/> : null}

            </TableCell>
            <TableCell
            onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
            style={{width: 100 / 8 + '%', fontSize: 10}} align="center">
            {Utils.newFormat(r.start)} - {Utils.newFormat(r.endz)}
        </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                data-tooltip-content={r.ext_note || ''}
                data-tooltip-id={r.id}
                style={{width: 100 / 8 + '%'}} align="center">
                <Tooltip className={"tooltip"} id={r.id}/>

                {r.extension ? r.extension : ''}
            </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                style={{width: 100 / 8 + '%'}} align="center">
                {Utils.secToHours(r.duration ? r.duration : 0)}
            </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                style={{width: 100 / 8 + '%'}}
                                   align="center">
            {r.line && r.line !== 'null' ? r.line : r.ext_num ? r.ext_num[0] : ''}
        </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
            style={{width: 100 / 8 + '%'}}
                               align="center">
            {r.in_num ? r.in_num : ''}
        </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                style={{width: 100 / 8 + '%'}}
                               align="center">
            {r.virtual ? r.virtual : ''}
        </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                style={{width: 100 / 8 + '%'}}
                               align="center">
            {r.disposition ? r.disposition : ''}
        </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
            data-tooltip-content={r.dest_note || ''}
            data-tooltip-id={r.id} style={{width: 100 / 8 + '%'}}
            align="center">
            {r.destination ? r.destination : ''}
        </TableCell>
            <TableCell
                onClick={() => setIsOpen(isOpen === r.linkedid ? '' : r.linkedid)}
                style={{width: 100 / 8 + '%',color:r.rate > 3?color:r.rate > 1?'yellow':r.rate?'red':'#ccc'}} align="center">
                {r.rate ? r.rate :
                    <DoNotDisturbIcon style={{color: '#ccc'}}/>}

            </TableCell>
            <TableCell align="center"
                       style={{width: 100 / 8 + '%', cursor: 'pointer'}}>
                <span className='d-flex history_audio'>
                    {Cookies.get('viewer') && !profile.isLimited ?
                        <PlayCircleIcon style={{color: '#ccc'}}/> :
                        <PlayCircleIcon onClick={() =>
                            handleAudio(r)}
                                        style={!r.isAudio ? {color: '#ccc'} : {color: color}}/>
                    }
                    {loading === r.linkedid?<Loader />:Cookies.get('viewer') && !profile.isLimited ?

                        <ChatBubbleIcon style={{color: '#ccc'}}/> :
                        <ChatBubbleIcon onClick={() =>
                            handleText(r)}
                                        style={!r.isAudio?{color: '#ccc'}:!r.isEmotion ? {color: '#52658c'} : {color: color}}/>
                    }
                </span>
            </TableCell>

        </TableRow>
    );
}

export default HistoryLine;
// loading === r.linkedid