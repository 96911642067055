import React, {useCallback, useEffect, useRef, useState} from 'react';
import DisplayTrack from "./DisplayTrack";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Loader from "./Loader";


function AudioPlayer({track,trackIndexSet,loading, interacted,setTrackIndex,handleText}) {
    const [currentTrack, setCurrentTrack] = useState(track[trackIndexSet]);
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef();
    const progressBarRef = useRef();
    useEffect(() => {
        if(interacted){
            setCurrentTrack(track[trackIndexSet])
        }
    },[trackIndexSet,track,interacted])
    const handleDownload = (url,id) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', id + '.mp3'); // Set the desired filename here
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => console.error('Error downloading audio file:', error));
    };
    return (
        <div className="audio-player">
            <div className="inner">

                <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <Controls {...{ audioRef, progressBarRef, duration, setTimeProgress,track,interacted,
                            trackIndexSet,
                            setTrackIndex,
                            setCurrentTrack,
                        }}/>
                        <DisplayTrack {...{ interacted, currentTrack, audioRef, setDuration, progressBarRef }} />
                    </div>
                    <div className="d-flex flex-row">
                        <span onClick={() => handleDownload(currentTrack.src,currentTrack.name)} style={{cursor:'pointer'}}>
                            <FontAwesomeIcon icon={faDownload} />
                        </span>
                        <span className="history_audio" onClick={() => handleText(currentTrack)} style={{cursor:'pointer',margin:'0 10px'}}>
                            {loading? <Loader />:<ChatBubbleIcon style={{fontSize:18}} />}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AudioPlayer;
