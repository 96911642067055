import React, {useEffect, useState} from 'react';
import IconForCall from "./IconForCall";
import {history1} from "../translate";

function DisplayTrack({ currentTrack,audioRef,setDuration,interacted, progressBarRef }) {
    const [loading, setLoading] = useState(false);

    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        setDuration(seconds);
        progressBarRef.current.max = seconds;
    };
    const [audioSrc, setAudioSrc] = useState('');

    const fetchAudio = async () => {
        try {
            setLoading(true)
            const response = await fetch(currentTrack?.src);
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            setAudioSrc(fileURL);
            setLoading(false)

        } catch (error) {
            console.error('Error fetching audio file:', error);
        }
    };
    useEffect(() => {
        (async () => await fetchAudio())()
    }, [currentTrack?.src]);
    useEffect(() => {
        const playAudio = async () => {
            if (audioRef.current && interacted) {
                try {
                    await audioRef.current.play();
                } catch (error) {
                    console.error("Playback error:", error);
                }
            }
        };

        if (audioSrc) {
            playAudio();
        }
    }, [audioSrc,interacted]); // Play audio only when audio source updates

    return (
        <div className="d-flex align-items-center">
            <audio src={audioSrc} ref={audioRef} onLoadedMetadata={onLoadedMetadata}/>
            <div className="audio-info">
                {loading?"Loading...":<div className="text">
                    <p className="title">{currentTrack?.type === 'Outgoing'?
                        <IconForCall color={history1.callType[2].color} path={history1.callType[2].path}/>:
                        <IconForCall color={history1.callType[0].color} path={history1.callType[0].path}/>

                    } </p>
                    <p>{currentTrack?.id}</p>
                </div>}
            </div>
        </div>
    );
}

export default DisplayTrack;
