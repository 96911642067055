import {
    INCLUDE_ADD_LOCAL, INCLUDE_CREATE_REQUEST, INCLUDE_CREATE_SUCCESS,
    INCLUDE_DELETE_LOCAL, INCLUDE_EDIT_SUCCESS,
    INCLUDE_FAIL,
    INCLUDE_REQUEST,
    INCLUDE_SUCCESS, PROVIDER_SUCCESS
} from '../actions/included';

const initialState = {
    includeData: [],
    provider: [],
    includeStatus: '',
    page:1,
    count:1,
    totalPages:1
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case INCLUDE_REQUEST: {
            return {
                ...state,
                includeStatus: 'request',
                includeData: [],
            };
        }
        case INCLUDE_CREATE_REQUEST: {
            return {
                ...state,
                includeStatus: 'request',
                includeData: [],
            };
        }
        case INCLUDE_SUCCESS: {
            return {
                ...state,
                includeStatus: 'success',
                includeData: action.payload.data.memberPhones,
                count: action.payload.data.total,
                page: action.payload.data.page,
            };
        }
        case INCLUDE_CREATE_SUCCESS: {
            return {
                ...state,
                includeStatus: 'success',
                includeData: action.payload.data.memberPhones,
                count: action.payload.data.total,
                page: action.payload.data.page,
            };
        }
        case INCLUDE_EDIT_SUCCESS: {
            return {
                ...state,
                includeStatus: 'success',
                includeData: action.payload.data.memberPhones,
                count: action.payload.data.total,
                page: action.payload.data.page,
            };
        }
        case PROVIDER_SUCCESS: {
            return {
                ...state,
                provider: action.payload.data.provider,
            };
        }
        case INCLUDE_ADD_LOCAL: {
            return {
                ...state,
                includeData:[ ...state.includeData, {...action.payload.data,id:new Date()}],
            };
        }
        case INCLUDE_DELETE_LOCAL: {
            return {
                ...state,
                includeData:state.includeData.filter(i => i.id !== action.payload.id),
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
