import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import { global, input, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import Api from "../Api";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {sipRequest, sipSingleRequest} from "../store/actions/sip";
import {crmRequest} from "../store/actions/crm";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight:'97vh'
};

function EditMoreSips({arr, open, setOpen}) {
    const [form, setForm] = useState({
        rec: 0,
        out: '',
        mob: 1,
        fixed: 2,
        inter: 3,
        payed: 4,
    })
    useEffect(() => {
        (async () => {
            if(arr[0]){
                await dispatch(sipSingleRequest(arr[0]))

            }
        })()
    }, [arr])
    const sipFix = useSelector(state => state.sip.sipFix)
    const dispatch = useDispatch();
    const location = useLocation();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleSave = useCallback(async () => {
        try {
            await Api.sipGlobalChange(arr,form)
            setOpen(false)
            toast.success('Successfully edited')
            await dispatch(sipRequest(Cookies.get("sip") ||query.limit || 10, query.page || 1, query.search || '', query.sortBy || '', query.sortOrder || ''))

        }catch (e) {
            toast.error(e.response.data.message)
        }

    }, [form,arr])
    const [checked, setChecked] = useState(true)

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {sip.edit[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>

                    <div className="row">
                        <label className="col-md-6 count " style={{margin: '20px 0'}}>
                                <span
                                    className="d-flex flex-row align-items-center justify-content-between">{sip.call_rec[+localStorage.getItem('atsLang')  || 1 ]}<input
                                    style={{width: 10}} checked={checked}
                                    onChange={(ev) => setChecked(ev.target.checked)} type="checkbox"/></span>
                            <input disabled={!checked} value={checked ? form.rec : 0}
                                   onChange={(ev) => handleChange('rec', checked ? +ev.target.value : 0)}
                                   type="number"/>
                        </label>
                        <label className="col-md-6 count" style={{margin: '20px 0'}}>
                            <span>{sip.out[+localStorage.getItem('atsLang')  || 1 ]}</span>
                            <select value={form.out} onChange={(ev) => handleChange('out', ev.target.value)}
                                    style={{height: 40, margin: '0 10px', border: '1px solid #ccc',}}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                {sipFix.map((o,i) => (
                                    <option key={i} value={o.name}>{o.name}</option>
                                ))}
                            </select>
                        </label>
                        <div className="col-md-6 count" style={{margin: '20px 0'}}>
                            <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.mobile[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input checked={form.mob}
                                       onChange={(ev) => handleChange('mob', ev.target.checked ? 1 : 0)}
                                       style={{width: 50}} type="checkbox"/>
                            </p>
                            <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.fix_num[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input checked={form.fixed}
                                       onChange={(ev) => handleChange('fixed', ev.target.checked ? 2 : 0)}
                                       style={{width: 50}} type="checkbox"/>
                            </p>
                            <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.international[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input checked={form.inter}
                                       onChange={(ev) => handleChange('inter', ev.target.checked ? 3 : 0)}
                                       style={{width: 50}} type="checkbox"/>
                            </p>
                            <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.payed[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                <input checked={form.payed}
                                       onChange={(ev) => handleChange('payed', ev.target.checked ? 4 : 0)}
                                       style={{width: 50}} type="checkbox"/>
                            </p>

                        </div>
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleSave} className="addBtnBig">
                            {global.edit[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default EditMoreSips;
