import {
  FORWARD_ADD_LOCAL,
  FORWARD_CREATE_SUCCESS,
  FORWARD_DELETE_LOCAL, FORWARD_EDIT_SUCCESS,
  FORWARD_FAIL,
  FORWARD_SUCCESS,
} from '../actions/forward';

const initialState = {
  forwardStatus:'',
  forwardData:[],
  page:1,
  count:1,
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FORWARD_SUCCESS: {
      return {
        ...state,
        forwardStatus: 'ok',
        forwardData:action.payload.data.forward,
        count: action.payload.data.total,
        page: action.payload.data.page,
      };
    } case FORWARD_EDIT_SUCCESS: {
      return {
        ...state,
        forwardStatus: 'ok',
        forwardData:action.payload.data.forward,
        count: action.payload.data.total,
        page: action.payload.data.page,
      };
    } case FORWARD_CREATE_SUCCESS: {
      return {
        ...state,
        forwardStatus: 'ok',
        forwardData:action.payload.data.forward,
        count: action.payload.data.total,
        page: action.payload.data.page,
      };
    }
    case FORWARD_FAIL: {
      return {
        ...state,
        forwardStatus: 'fail',
      };
    }
    case FORWARD_ADD_LOCAL: {
      return {
        ...state,
        forwardStatus: 'ok',
        forwardData: [...state.forwardData,{...action.payload.data,id:new Date()},]
      };
    }
    case FORWARD_DELETE_LOCAL: {
      return {
        ...state,
        forwardStatus: 'ok',
        forwardData: state.forwardData.filter(f => f.id !== action.payload.id)
      };
    }


    default: {
      return {
        ...state,
      };
    }
  }
}
