import React from 'react';

function IconForCall({color,path}) {
    return (
        <div data-v-3ab865ea=""
             className="itl-svg svg-call-outgoing-24 with-text"
             style={{color: color}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 style={{width: 24, height: 24, maxWidth: 24, maxHeight: 24}}>
                <path
                    d={path}
                    fill="currentColor"/>
            </svg>
        </div>
    );
}

export default IconForCall;