export const LOGS_REQUEST = 'LOGS_REQUEST';
export const LOGS_SUCCESS = 'LOGS_SUCCESS';
export const LOGS_FAIL = 'LOGS_FAIL';

export function logRequest(limit,page,search) {
    return {
        type: LOGS_REQUEST,
        payload: { limit,page,search },
    };
}

export const LOGS_SINGLE_REQUEST = 'LOGS_SINGLE_REQUEST';
export const LOGS_SINGLE_SUCCESS = 'LOGS_SINGLE_SUCCESS';
export const LOGS_SINGLE_FAIL = 'LOGS_SINGLE_FAIL';

export function ipCreateRequest(ip) {
    return {
        type: LOGS_SINGLE_REQUEST,
        payload: { ip },
    };
}



