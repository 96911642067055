import React, {useCallback, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {file, global, input, weekDays} from "../translate";
import {useDispatch} from "react-redux";
import {sipLocalRequest} from "../store/actions/sip";
import {fileIdictCreateRequest} from "../store/actions/file";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700?700:window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: window.innerWidth > 700?'70vh':'97vh',
    overflow: 'auto'
};

function TextToVoice({open, setOpen}) {
    const [form, setForm] = useState({
        translated_to:'hy-AM',
        voiceName:'anahit',
        name:'',
        text:'',
        language:'arm'
    })

    const [lang,setLang] = useState({
        'en-US': ['john', 'alison'],
        'ru-RU': ['alena', 'filipp'],
        'hy-AM': ['anahit', 'ara']
    })

    const dispatch = useDispatch()

    const handleChange = useCallback((name,ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]:ev
        }))

        if(name === 'translated_to'){
            setForm((prevState) => ({
                ...prevState,
                voiceName:lang[ev][0],
                language:ev === 'hy-AM'? 'arm':ev === 'en-US'?'eng':'ru'
            }))
        }

    }, [lang])
    const handleAdd = useCallback(async () => {
        await dispatch(fileIdictCreateRequest(form))
        setOpen(false)
    }, [form])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {file.voice[+localStorage.getItem('atsLang')  || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            x
                        </span>
                    </div>
                    <div id="transition-modal-description sip_content" style={{marginTop: 20}}>
                        <div className="row">
                            <div className="col-md-12" style={{marginTop:10}}>
                                <span>{file.myVT[+localStorage.getItem('atsLang')  || 1]}</span>
                                <textarea value={form.text} onChange={ev => handleChange('text',ev.target.value)} style={{width:'100%',height:90 ,   outline: 'none',
                                    border: '1px solid'}} />
                            </div>
                            <label className="col-md-6" style={{marginTop:20}}>
                                <span>{file.myVT[+localStorage.getItem('atsLang')  || 1]}</span>
                                <textarea value={form.name} onChange={ev => handleChange('name',ev.target.value)} style={{width:'100%',height:60 ,outline: 'none',
                                    border: '1px solid'}} />
                            </label>
                            <label className="col-md-6"  style={{marginTop:20}} >
                                <span>{file.lang[+localStorage.getItem('atsLang')  || 1]}</span>
                                <select value={form.translated_to}
                                        onChange={(ev) => handleChange('translated_to',ev.target.value)}
                                        style={{width: '100%', height: 60, outline: 'none',
                                    border: '1px solid'}}>
                                    <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    <option value='hy-AM'>{file.arm[+localStorage.getItem('atsLang')  || 1]}</option>
                                    <option value='en-US'>{file.eng[+localStorage.getItem('atsLang')  || 1]}</option>
                                    <option value='ru-RU'>{file.ru[+localStorage.getItem('atsLang')  || 1]}</option>
                                </select>
                            </label>
                           <div className="col-md-6 ">
                               <label className="addBtn d-flex align-items-center justify-content-center col-md-6" style={{
                                   background: '#555',
                                   marginTop: '10px',
                                   height:'60px',
                                   width:'100%',
                                   marginBottom: '10px',
                               }}>
                                   <span className="forFile">{form.file?form.file.name:file.choose[+localStorage.getItem('atsLang')  || 1]}</span>
                                   <input onChange={(ev) => handleChange('file',ev.target.files[0])} className="ok" style={{display: 'none'}} type="file"/>
                               </label>
                           </div>
                            <label className="col-md-6"  style={{marginTop:7}} >
                                {lang[form.translated_to].map(s => (
                                    <div>
                                        <div className="radio-item"><input name="radio" onClick={() => handleChange('voiceName',s)} checked={form.voiceName === s} id={s} type="radio"/><label
                                            htmlFor={s}>{s}</label></div>
                                    </div>
                                ))}
                            </label>
                        </div>
                        <div className="d-flex justify-content-center" style={{marginTop: '40px'}}>
                            <button className="addBtn"
                                    onClick={handleAdd}
                                    style={{margin: '10px 0'}}>{global.add[+localStorage.getItem('atsLang')  || 1]}</button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default TextToVoice;
