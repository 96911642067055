import {
    SIP_CREATE_FAIL,
    SIP_CREATE_REQUEST,
    SIP_CREATE_SUCCESS,
    SIP_DELETE_FAIL,
    SIP_DELETE_REQUEST,
    SIP_DELETE_SUCCESS,
    SIP_EDIT_FAIL,
    SIP_EDIT_REQUEST,
    SIP_EDIT_SUCCESS,
    SIP_FAIL,
    SIP_LOCAL_ADD,
    SIP_LOCAL_DELETE,
    SIP_PROVIDER_CREATE_SUCCESS,
    SIP_PROVIDER_EDIT_SUCCESS,
    SIP_PROVIDER_LOCAL_ADD,
    SIP_PROVIDER_LOCAL_DELETE,
    SIP_PROVIDER_SUCCESS,
    SIP_REQUEST,
    SIP_SINGLE_REQUEST,
    SIP_SINGLE_SUCCESS,
    SIP_SUCCESS
} from '../actions/sip';
import {CRM_CREATE_FAIL, CRM_CREATE_REQUEST, CRM_CREATE_SUCCESS} from "../actions/crm";


const initialState = {
    sipData: [],
    sipSingleData: {},
    sipTelegramData: {},
    sipFix: [],
    sipStatus: '',
    sipProvider: [],
    crmSingle: [],
    sipProviderStatus: '',
    page: 1,
    total: 0,
    pageS: 1,
    totalS: 0,
    totalPage: 1
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SIP_REQUEST: {
            return {
                ...state,
                sipStatus: 'request',
                // sipData: [],
                // page: 1,
                // total: 0,
                // totalPage: 1
            };
        }
        case SIP_SUCCESS: {
            return {
                ...state,
                sipStatus: 'success',
                sipData: action.payload.data.sip,
                page: action.payload.data.page,
                sipFix: action.payload.data.fixNum,
                total: action.payload.data.total,
                totalPage: action.payload.data.count
            };
        }
        case SIP_PROVIDER_SUCCESS: {
            return {
                ...state,
                sipProviderStatus: 'success',
                sipProvider: action.payload.data.sip,
                pageS: action.payload.data.page,
                totalS: action.payload.data.count,
            };
        }
        case SIP_PROVIDER_CREATE_SUCCESS: {
            return {
                ...state,
                sipProviderStatus: 'success',
                sipProvider: action.payload.data.sip,
                pageS: action.payload.data.page,
                totalS: action.payload.data.count,
            };
        }
        case SIP_PROVIDER_EDIT_SUCCESS: {
            return {
                ...state,
                sipProviderStatus: 'success',
                sipProvider: action.payload.data.sip,
                pageS: action.payload.data.page,
                totalS: action.payload.data.total,
            };
        }

        case SIP_SINGLE_REQUEST: {
            return {
                ...state,
                sipSingleStatus: 'request',
                sipSingleData: {},
                sipFix: [],
                sipTelegramData: {},
            };
        }
        case SIP_SINGLE_SUCCESS: {
            return {
                ...state,
                sipSingleStatus: 'success',
                sipSingleData: action.payload.data.single,
                sipFix: action.payload.data.fixNum,
                sipTelegramData: action.payload.data.telegram,
                crmSingle: action.payload.data.single.crm
            };
        }
        case SIP_CREATE_SUCCESS: {
            return {
                ...state,
                sipStatus: 'success',
                sipData: action.payload.data.sip,
                page: action.payload.data.page,
                total: action.payload.data.total,
                totalPage: action.payload.data.totalPages
            };
        }
        case SIP_EDIT_SUCCESS: {
            return {
                ...state,
                sipStatus: 'success',
                sipData: action.payload.data.sip,
            };
        }
        case SIP_DELETE_SUCCESS: {
            return {
                ...state,
                sipStatus: 'success',
                // sipData: action.payload.data,
            };
        }
        case SIP_CREATE_REQUEST: {
            return {
                ...state,
                sipStatus: 'request',
                sipData: {},
            };
        }
        case SIP_EDIT_REQUEST: {
            return {
                ...state,
                sipStatus: 'request',
                sipData: {},
            };
        }
        case SIP_DELETE_REQUEST: {
            return {
                ...state,
                sipStatus: 'request',
                // sipData: {},
            };
        }
        case SIP_CREATE_FAIL: {
            return {
                ...state,
                sipStatus: 'fail',
                sipData: {},
            };
        }
        case SIP_EDIT_FAIL: {
            return {
                ...state,
                sipStatus: 'fail',
                sipData: {},
            };
        }
        case SIP_DELETE_FAIL: {
            return {
                ...state,
                sipStatus: 'fail',
                // sipData: {},
            };
        }
        case SIP_FAIL: {
            return {
                ...state,
                sipStatus: 'fail',
                page: 1,
                total: 0,
                totalPage: 1
            };
        }
        case SIP_LOCAL_ADD: {
            let page = 0
            if ([...state.sipData, {...action.payload.data, id: new Date()}].length > 10) {
                page = 1
            }
            return {
                ...state,
                sipData: [...state.sipData, {...action.payload.data, id: new Date()}],
                total: [...state.sipData, {...action.payload.data, id: new Date()}].length,
                totalPage: state.totalPage + page
            };
        }
        case SIP_PROVIDER_LOCAL_ADD: {
            return {
                ...state,
                sipProvider: [...state.sipProvider, {...action.payload.data, id: new Date()}],
            };
        }
        case SIP_LOCAL_DELETE: {
            return {
                ...state,
                sipData: state.sipData.filter(s => s.buddy !== action.payload.id),
            };
        }
        case SIP_PROVIDER_LOCAL_DELETE: {
            return {
                ...state,
                sipProvider: state.sipProvider.filter(s => s.id !== action.payload.id),
            };
        }

        case CRM_CREATE_REQUEST: {
            return {
                ...state,
                crmSingle: []
            };
        }
        case CRM_CREATE_SUCCESS: {
            return {
                ...state,
                crmSingle: action.payload.data.single.crm
            };
        }
        case CRM_CREATE_FAIL: {
            return {
                ...state,
                crmSingle: []
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}
