import {call, put, takeLatest} from 'redux-saga/effects';
import {
    IP_CREATE_FAIL, IP_CREATE_REQUEST,
    IP_CREATE_SUCCESS,
    IP_FAIL,
    IP_REQUEST,
    IP_SUCCESS,
} from '../actions/ip';
import Api from '../../Api';
import {toast} from "react-toastify";
import {global} from "../../translate";

function* handleIpRequest(action) {
    try {
        const {limit,page,search,sort,sortBy} = action.payload
        const {data} = yield call(Api.getIp,limit,page,search,sort,sortBy);
        yield put({
            type: IP_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: IP_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleCreateIpRequest(action) {
    try {
        const {limit,page,search} = action.payload

        const {data} = yield call(Api.createIp,action.payload.ip.ip,limit,page,search);
        toast.success(global.create[+localStorage.getItem('atsLang')])

        yield put({
            type: IP_CREATE_SUCCESS,
            payload: {data:data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: IP_CREATE_FAIL,
            payload: {error: e.response},
        });
    }
}



export default function* watcher() {
    yield takeLatest(IP_REQUEST, handleIpRequest);
    yield takeLatest(IP_CREATE_REQUEST, handleCreateIpRequest);

}
