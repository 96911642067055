import React, {useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";

import Smile from "./Smile";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function Emotions({open, setOpen}) {
    let [max,setMax] = useState('')
    useEffect(() => {
        const maxEmotion = Object.keys(open?.emotion?.emotions).reduce((a, b) => (open?.emotion?.emotions[a] > open?.emotion?.emotions[b] ? a : b));
        setMax(maxEmotion)
    }, [open])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => {
                    setOpen({})
                }}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Emotions
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen({})}>
                                <CloseIcon/>
                            </span>
                        </div>

                        <div style={{margin: '20px 0'}}
                             className="d-flex flex-row align-items-center justify-content-between">
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile size={max === 'happy'?'58':'40'} color={max === 'happy'?'#4ab847':'#222222'} icon="happy"/>
                                <span>{Math.round(open?.emotion?.emotions?.happy)} %</span>
                            </p>
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile icon="sad" size={max === 'sad'?'58':'40'} color={max === 'sad'?'#1a61af':'#222222'}/>
                                <span>{Math.round(open?.emotion?.emotions?.sad)} %</span>

                            </p>
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile size={max === 'neutral'?'60':'41'} icon="neutral" color={max === 'neutral'?'#aaaaaa':'#222222'}/>
                                <span>{Math.round(open?.emotion?.emotions?.neutral)} %</span>
                            </p>
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile icon="angry" color={max === 'angry'?'#e71e24':'#222222'} size={max === 'angry'?'60':'41'}/>
                                <span>{Math.round(open?.emotion?.emotions?.angry)} %</span>
                            </p>
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile icon="surprised" color={max === 'surprise'?'#fbe925':'#222222'} size={max === 'surprise'?'60':'41'}/>
                                <span>{Math.round(open?.emotion?.emotions?.surprise)} %</span>
                            </p>
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile icon="scared" color={max === 'fear'?'#f6911f':'#222222'} size={max === 'fear'?'58':'40'}/>
                                <span>{Math.round(open?.emotion?.emotions?.fear)} %</span>
                            </p>
                            <p className="d-flex flex-column justify-content-center align-items-center">
                                <Smile icon="disgust" color={max === 'disgust'?'#612d91':'#222222'} size={max === 'disgust'?'58':'40'}/>
                                <span>{Math.round(open?.emotion?.emotions?.disgust)} %</span>
                            </p>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <p dangerouslySetInnerHTML={{__html: open.emotion.summary}}/>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default Emotions;