import React, {useCallback, useEffect, useState} from 'react';
import {amo, historyHeader, input, global, crm} from "../translate";
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Api from "../Api";
import {toast} from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // height: window.innerWidth > 600?'50vh':'97vh',
    overflow: 'auto'
};

function AmoCall({open, setOpen}) {
    const [obj, setObj] = useState({
        incoming: '',
        outgoing: '',
        missed: '',
        prefix: "374",
        default: 0,
    })
    const handleRadioChange = useCallback((name, index, ev) => {
        setObj(prev => {
            return {...prev, [name]: ev.target.value};
        });
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            for (let openElement of open) {
                if (openElement.id) {
                    await Api.crmConfigs(obj, openElement.sip, openElement.id)
                }
            }
            toast.success("Successfully saved!")
            setOpen(false)
        } catch (e) {
            toast.error(e.response.data.message)

        }
    }, [obj, open])

    useEffect(() => {
        (async () => {
            try {
                if (open[0] && open[0].id) {
                    let {data} = await Api.getCrmConfigs(open[0].id)
                    if (data.config.access_actions) {
                        const jsonString = String.fromCharCode(...data.config.access_actions.data);
                        const jsonData = JSON.parse(jsonString);
                        setObj(jsonData)
                    }
                }
            } catch (e) {
                toast.error(e.response.data.message)

            }
        })()
    }, [open])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {crm.config_t[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    <div className="call_settings">
                        <table className="w-100">
                            <tr>
                                <th/>
                                <th>{historyHeader.nothing[+localStorage.getItem('atsLang') || 1]}</th>
                                <th>{historyHeader.contact[+localStorage.getItem('atsLang') || 1]}</th>
                                <th>{historyHeader.contact_lead[+localStorage.getItem('atsLang') || 1]}</th>
                                <th>{historyHeader.unsorted[+localStorage.getItem('atsLang') || 1]}</th>
                            </tr>
                            <tr>
                                <td>{historyHeader.incoming[+localStorage.getItem('atsLang') || 1]}</td>
                                <td><input value="nothing" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 0, ev)}
                                           checked={obj.incoming === 'nothing'}/></td>
                                <td><input value="contact" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 1, ev)}
                                           checked={obj.incoming === 'contact'}/></td>
                                <td><input value="contact_deal" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 2, ev)}
                                           checked={obj.incoming === 'contact_deal'}/></td>
                                <td><input value="none" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 3, ev)}
                                           checked={obj.incoming === 'none'}/></td>
                            </tr>
                            <tr>
                                <td>{historyHeader.outgoing[+localStorage.getItem('atsLang') || 1]}</td>
                                <td><input value="nothing" name="outgoing" type="radio"
                                           onClick={(ev) => handleRadioChange('outgoing', 0, ev)}
                                           checked={obj.outgoing === 'nothing'}/></td>
                                <td><input value="contact" name="outgoing" type="radio"
                                           onClick={(ev) => handleRadioChange('outgoing', 1, ev)}
                                           checked={obj.outgoing === 'contact'}/></td>
                                <td><input value="contact_deal" name="outgoing" type="radio"
                                           onClick={(ev) => handleRadioChange('outgoing', 2, ev)}
                                           checked={obj.outgoing === 'contact_deal'}/></td>
                                <td/>
                            </tr>
                            <tr>
                                <td>{historyHeader.missed[+localStorage.getItem('atsLang') || 1]}</td>
                                <td><input value="nothing" name="missed" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 0, ev)}
                                           checked={obj.missed === 'nothing'}/></td>
                                <td><input value="contact" name="missed" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 1, ev)}
                                           checked={obj.missed === 'contact'}/></td>
                                <td><input value="contact_deal" name="contact" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 2, ev)}
                                           checked={obj.missed === 'contact_deal'}/></td>
                                <td><input value="none" name="missed" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 3, ev)}
                                           checked={obj.missed === 'none'}/></td>
                            </tr>
                        </table>
                        <hr/>
                        <label className="col-md-12">
                            <span className='d-flex align-items-center'>
                                <span>{amo.paragraph_p3[+localStorage.getItem('atsLang') || 1]}</span>
                                <input className="call_check" checked={obj.default} type="checkbox"
                                       onClick={(ev) => setObj(prev => ({
                                           ...prev,
                                           default: ev.target.checked ? 1 : 0
                                       }))}/>
                            </span>
                        </label>
                        <hr/>
                        <div className="col-md-12">
                            <div>
                                <p style={{marginBottom:10}}><b>{amo.paragraph_p4[+localStorage.getItem('atsLang') || 1]}</b></p>
                                <div className='d-flex align-items-center'>

                                    <label>
                                        <input value="" name="prefix" type="radio"
                                               onClick={(ev) => setObj(prev => ({...prev, prefix: ev.target.value}))}
                                               checked={obj.prefix === ''}/>
                                        <span className="radioSpan">
                                        {amo.prefix[+localStorage.getItem('atsLang') || 1]}
                                    </span>
                                    </label>
                                    <label>
                                        <input value="0" name="prefix" type="radio"
                                               onClick={(ev) => setObj(prev => ({...prev, prefix: ev.target.value}))}
                                               checked={obj.prefix === '0'}/>
                                        <span className="radioSpan">
                                        {amo.prefix0[+localStorage.getItem('atsLang') || 1]}
                                    </span>
                                    </label>
                                    <label>
                                        <input value="374" name="prefix" type="radio"
                                               onClick={(ev) => setObj(prev => ({...prev, prefix: ev.target.value}))}
                                               checked={obj.prefix === '374'}/>
                                        <span className="radioSpan">
                                        {amo.prefix374[+localStorage.getItem('atsLang') || 1]}
                                    </span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        {/*<div className="row">*/}
                        {/*    <label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*    </label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label>*/}
                        {/*</div>*/}
                        <hr/>

                        <button onClick={handleSubmit} className="addBtn">
                            {global.edit[+localStorage.getItem('atsLang') || 1]}
                        </button>

                    </div>

                </Box>
            </Fade>
        </Modal>
    );
}

export default AmoCall;
