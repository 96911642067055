import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {included, input, global, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {includeCreateRequest, providerRequest} from "../store/actions/included";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";
import Api from "../Api";
import IncludeNumberBlock from "./IncludeNumberBlock";
import AddIcon from '@mui/icons-material/Add';
const {REACT_APP_SERVER} = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '97vh'
};

function AddInclude({open, setOpen}) {
    const [form, setForm] = useState({
        note: '',
        isMob: Cookies.get('viewer') && Cookies.get('original') ? '' : 42,
        phone: '',
        isShort: false,
        short: '',
    })

    const [multiple, setMultiple] = useState([])
    const dispatch = useDispatch()
    const provider = useSelector(state => state.included.provider)
    const location = useLocation()
    const userData = useSelector((state) => state.users.ursData)
    const [hash, setHash] = useState(1)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleChange = useCallback((name, ev) => {
        if (name === 'isShort' && !ev) {
            setForm((prevState) => ({
                ...prevState,
                short: ''
            }))
        }

        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleWheel = useCallback((event) => {
        event.target.blur();
        event.preventDefault();
    }, []);
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(includeCreateRequest(Cookies.get("included") || query.limit, query.page, query.search, form, multiple, query.sortBy, query.sortOrder))
        setOpen(false)
    }, [form, location.search, multiple])
    useEffect(() => {
        (async () => {
            await dispatch(providerRequest())
        })()
    }, [])

    useEffect(() => {
        if (!hash) {
            let multi = []
            for (let i = 0; i < 1; i++) {
                multi.push({
                    phone: '',
                    short: '',
                    isShort: false,
                })
            }
            setMultiple(multi)
        } else {
            setMultiple([])
        }
    }, [hash])

    const handleAddNumber = useCallback(() => {
        setMultiple((prevState) => ([
            ...prevState,
            {
                phone: '',
                short: '',
                isShort: false,
            }
        ]))
    }, [])
    const handleGenerate = useCallback(async (isShort) => {
        if (isShort && hash) {
            const {data} = await Api.generate(11)
            handleChange('short', data.counter)
        } else {
            let counter = 11;
            let multi = [...multiple]

            for (let multiElement of multi) {
                if(multiElement.phone){
                    const {data} = await Api.generate(+counter + 1)
                    multiElement.short = data.counter
                    multiElement.isShort = true
                    counter = +data.counter
                }

            }
            setMultiple(multi)

        }
    }, [hash, multiple])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {included.title[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    {userData && REACT_APP_SERVER === "Ucom" ?
                        <div style={{marginTop: 10}}>
                            <span className={`included_link ${hash ? 'active' : ''}`} onClick={() => setHash(1)}>
                                {included.includedAd[+localStorage.getItem('atsLang') || 1]}
                            </span>
                        <span className={`included_link ${!hash ? 'active' : ''}`} onClick={() => setHash(0)}>
                            {included.includedAd2[+localStorage.getItem('atsLang') || 1]}
                        </span>
                    </div> : null}
                    <form onSubmit={handleAdd}>

                        {hash ?
                            <IncludeNumberBlock
                                handleGenerate={handleGenerate}
                                handleChange={handleChange}
                                form={form}
                                isMultiple={false}
                                handleWheel={handleWheel}
                            />
                            : multiple?.map((item, index) => (
                                <IncludeNumberBlock
                                    key={index}
                                    handleGenerate={handleGenerate}
                                    handleChange={handleChange}
                                    form={item}
                                    index={index}
                                    setMultiple={setMultiple}
                                    multiple={multiple}
                                    isMultiple={true}
                                    handleWheel={handleWheel}
                                />
                            ))}
                        {hash ? null : <div className="col-md-12 count" style={{margin: '20px 0'}}>
                            <div className="d-flex justify-content-end align-items-center">
                                <div
                                    className="adminLoginBtn p-0" style={{marginLeft: 10,borderRadius:'100%', marginBottom: 0}}
                                    onClick={handleAddNumber}>
                                    <AddIcon/>
                                    {/*{global.add[+localStorage.getItem('atsLang') || 1]} {included.phone[+localStorage.getItem('atsLang') || 1]}*/}
                                </div>
                                {multiple.length <= 1?null:<div className="adminLoginBtn" style={{marginLeft: 10, marginBottom: 0}}
                                     onClick={() => handleGenerate(form.isShort)}>
                                    {sip.generateAll[+localStorage.getItem('atsLang') || 1]}
                                </div>}
                            </div>

                        </div>}
                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                            <label className="count">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <input value={form.note} onChange={(ev) => handleChange('note', ev.target.value)}/>
                            </label>
                        </div>
                        <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                               {included.mobile[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                            <select
                                disabled={!(Cookies.get('viewer') && Cookies.get('original'))}
                                value={form.isMob} onChange={(ev) => handleChange('isMob', ev.target.value)}
                                style={{height: 40, border: '1px solid #ccc', outline: 'none'}}>
                                <option value="">{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                {(Cookies.get('viewer') && Cookies.get('original')) ? provider.length ? provider.map(p => (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )) : null : <option value='42'>sipapp</option>}
                            </select>
                        </label>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {sip.add_btn[+localStorage.getItem('atsLang') || 1]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddInclude;
