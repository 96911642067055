import React, {useCallback, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import img from "../assets/img/logo22.png";
import uc from "../assets/img/image_ucom.png";
import {menu} from "../translate";
import LockIcon from "@mui/icons-material/Lock";
import _ from 'lodash'
import Account from "../helpers/Account";
import Api from "../Api";
import Utils from "../Utils";
import Cookies from "js-cookie";
import {profileRequest, profileUrsRequest} from "../store/actions/users";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
const {REACT_APP_SERVER} = process.env;
import ats from '../assets/img/logo_light.png'



function UrsUsed(props) {
    let sip = 0
    let gsm = 0
    let did = 0
    let ucomsip = 0
    let clgroup = 0
    let countedPrice = 0
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const profile = useSelector(state => state.users.ursData)
    const [newData,setData] = useState('')
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getUsed()
                // console.log(data.data)
                // let l = data.data.reduce((result, item) => {
                //     const yearMonth = item.registered.slice(0, 7); // Extract year-month
                //     if (!result[yearMonth]) {
                //         result[yearMonth] = [];
                //     }
                //     result[yearMonth].push(item);
                //     return result;
                // }, {});
                // const sortedKeys = Object.keys(l).sort();
                //
                // l = sortedKeys.reduce((result, key) => {
                //     result[key] = l[key];
                //     return result;
                // }, {});
                // console.log(l)
                setData(data)
            } catch (e) {
            }
        })()
    }, [])
    useEffect(() => {
        if (!Account.getUrsToken()) {
            navigate('/admin_login')
        }
    }, [])
    const handleId = useCallback(async (id) => {
        try {
            Cookies.set('viewer', id)
            Cookies.set('original', profile.id)
            Cookies.set('key', profile.uid)
            const {data} = await Api.loginAsUser(id, profile.id, profile.uid)
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('refresh_token', data.refresh_token)
            await dispatch(profileRequest())
            navigate('/')
        }catch (e) {

        }
    }, [profile])
    useEffect(() => {
        (async () => {
            await dispatch(profileUrsRequest())
        })()
    }, [])
    return (
        <>
            <Helmet>
                <title>Urs</title>
            </Helmet>
            <header className="header">
                <div className="header_container container" style={window.innerWidth > 1030 ? {} : {maxWidth: '100%'}}>
                    <div className="header_block">
                        <div className="left">
                            {REACT_APP_SERVER ==='Beeline'?<Link to="/">
                                <figure>
                                    <img style={{width:100}} src={img} alt=''/>
                                </figure>
                            </Link>:REACT_APP_SERVER ==='ATS'?<Link to="/">
                                <figure>
                                    <img style={{width:100}} src={ats} alt=''/>
                                </figure>
                            </Link>:<Link to="/">
                                <figure>
                                    <img src={uc} alt=''/>
                                </figure>
                            </Link>}

                        </div>
                        <div className="right">
                            <div className="logout">
                                <Link to="/urs_online"> {menu.online[0]}</Link>
                            </div>
                            <div className="logout">
                                <Link to="/admin_login"><LockIcon
                                    sx={{color: '#CD405F', fontSize: 20}}/> {menu.logout[0]}</Link>
                            </div>

                        </div>

                    </div>
                </div>
            </header>
            <div className="urs_table" style={{display: 'flex', justifyContent: 'center'}}>
                <div className=" tableParent  file" style={window.innerWidth <= 1024?{overflow:'scroll',background: 'white', padding: 35,width:'95vw',overflowY:'hidden',}:{overflowY:'hidden',background: 'white', padding: 35,width:'75vw'}}>
                    <table cellPadding="0" cellSpacing="0">

                        {/*{newData?.length !== 0 ? Object.keys(newData).map(m => {*/}
                        {/*    let c = 0*/}
                        {/*    newData[m].map((s,i) => {*/}
                        {/*        if (s.gsm || s.did || s.sip || s.ucomsip){*/}
                        {/*            if(s.clgroup !== clgroup){*/}
                        {/*                c++*/}
                        {/*            }*/}
                        {/*        }else{*/}
                        {/*            clgroup = s['clgroup'];*/}
                        {/*        }})*/}
                        {/*    // countedPrice += newData[m].reduce((sum, item) => sum + item.countedPrice, 0)*/}


                        {/*    return (*/}
                        {/*        <tbody>*/}
                        {/*            <tr style={{background:'#e0e0e0'}} key={m}>*/}
                        {/*                <th style={{textAlign:'left'}} align={"left"} colSpan={5}>*/}
                        {/*                    Ամսաթիվ {m}*/}
                        {/*                </th>*/}
                        {/*                <th style={{textAlign:'left'}} align={"left"} colSpan={5}>*/}
                        {/*                    Գրանցվածների թիվը - {c}*/}
                        {/*                </th>*/}

                        {/*                <th style={{textAlign:'left'}} align={"left"} colSpan={5}>*/}
                        {/*                    {newData[m].reduce((sum, item) => sum + item.countedPrice, 0)}*/}
                        {/*                </th>*/}
                        {/*            </tr>*/}
                        {/*            {newData[m].map((s,i) => {*/}
                        {/*                if (s?.gsm || s?.did || s?.sip || s?.ucomsip){*/}
                        {/*                    sip+=s?.sip*/}
                        {/*                    gsm+=s?.gsm*/}
                        {/*                    did+=s?.did*/}
                        {/*                    ucomsip+=s?.ucomsip*/}
                        {/*                    countedPrice+=s.clgroup !== clgroup?s?.countedPrice:0*/}
                        {/*                    return (*/}
                        {/*                        <tr  key={_.uniqueId()} role="checkbox" tabIndex={-1}>*/}
                        {/*                            <td >{s.clgroup !== clgroup?i+1:''}</td>*/}
                        {/*                            <td>*/}
                        {/*                                {s.clgroup !== clgroup?Utils.formatDate(s.registered):'Group Management'}*/}
                        {/*                            </td>*/}
                        {/*                            <td style={{cursor:'pointer', textDecoration:'underline'}} onClick={() => handleId(s.id)} >*/}
                        {/*                                {s.fio}*/}
                        {/*                            </td>*/}
                        {/*                            <td  >*/}
                        {/*                                {s?.gsm}*/}
                        {/*                            </td>*/}
                        {/*                            <td  >*/}
                        {/*                                {s?.sip}*/}
                        {/*                            </td>*/}
                        {/*                            <td  >*/}
                        {/*                                {s?.did}*/}
                        {/*                            </td>*/}
                        {/*                            <td  >*/}
                        {/*                                {s?.ucomsip}*/}
                        {/*                            </td>*/}
                        {/*                            <td  >*/}
                        {/*                                {s.clgroup !== clgroup?s?.countedPrice:''}*/}
                        {/*                            </td>*/}
                        {/*                            <td  >*/}
                        {/*                                /!*{s.newActive[0]?.sstatus}*!/*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}
                        {/*                    )*/}
                        {/*                }*/}
                        {/*                clgroup = s['clgroup'];*/}

                        {/*            })}*/}
                        {/*        </tbody>*/}

                        {/*    )*/}

                        {/*}):*/}
                        {newData?<tbody dangerouslySetInnerHTML={{__html:newData}}/>:
                        <tbody><tr><td style={{textAlign:'center'}} colSpan={17}>Loading...</td></tr></tbody>}
                        <tr>
                            <td colSpan={4}/>
                            <td>{newData?.gsmm}</td>
                            <td>{newData?.sipp}</td>
                            <td>{newData?.didd}</td>
                            <td>{newData?.ucomsipp}</td>
                            <td>{newData?.all}</td>
                        </tr>

                    </table>
                </div>
            </div>


        </>

    );
}

export default UrsUsed;
