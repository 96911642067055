import React, {useCallback, useEffect, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal} from "@mui/material";
import {monday} from "../translate";
import img from '../assets/img/amo.png'
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
const {REACT_APP_SERVER} = process.env;
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    overflow: 'hidden'
};

function MondayEnter({open, setOpen}) {
    const navigate = useNavigate()
    const [copy, setCopied] = useState(false)
    const [code, setCode] = useState('')

    const handleCopy = useCallback(() => {
        if (navigator.clipboard) {
            setCopied(true)
            navigator.clipboard.writeText(code).catch(function () {});
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    },[code])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>

                    <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between">
                            <h3>{monday.header[+localStorage.getItem('atsLang')  || 1]}</h3>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen('')
                                navigate('/crm_integration')
                            }}>
                            <CloseIcon/>
                            </span>
                        </div>
                        <p className="crm_title"><span
                            className="number">1. </span><span>{REACT_APP_SERVER === 'ATS'?monday.titleA[+localStorage.getItem('atsLang')  || 1]:monday.title[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span>{monday.paragraph_p1[+localStorage.getItem('atsLang')  || 1]}
                            <a href={REACT_APP_SERVER === 'ATS'?monday.link.linkA : monday.link.link} target='_blank'>{REACT_APP_SERVER === 'ATS'?monday.link.textA[+localStorage.getItem('atsLang')  || 1]:monday.link.text[+localStorage.getItem('atsLang')  || 1]}</a>{monday.paragraph_p2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_title"><span
                            className="number">2. </span><span>{monday.title2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span><a href={monday.link.link}>{monday.paragraph2[+localStorage.getItem('atsLang')  || 1]}</a></span>
                        </p>
                    </div>
                    <div id="transition-modal-description sip_content" className="test_mode">
                        <div className="d-flex justify-content-center">
                            <button style={{padding: '6px 17px'}} className="addBtn" onClick={()=>setOpen('')}>
                                OK
                            </button>
                        </div>
                        {copy ? <div className="copied">Copied</div> : null}
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default MondayEnter;
