import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {auto, ivr, menu, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {
    autoCallDeleteLocal,
    autoCallDeleteRequest,
    autoCallRequest,
    autoCallStatusRequest
} from "../store/actions/autoCall";
import AddAutoCall from "../components/AddAutoCall";
import Api from "../Api";
import {ivrDelete} from "../store/actions/ivr";


function AutoCall(props) {
    const autoCallData = useSelector(state => state.autoCall.autoCallData)
    const [create, setCreate] = useState(false)
    const dispatch = useDispatch()
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(autoCallDeleteLocal(id))
                await dispatch(autoCallDeleteRequest(id))
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    useEffect(() => {
        (async () => {
            await dispatch(autoCallRequest())
        })()
    }, [])

    const handleStatus = useCallback(async (id, status) => {
        let text = +localStorage.getItem('atsLang') === 1?"Do you want to modify the specified row?":+localStorage.getItem('atsLang') === 2?"Вы хотите изменить указанную строку?":"Դուք ցանկանու՞մ էք Փոփոխել նշված տողը";
        if (confirm(text) === true) {
            await dispatch(autoCallStatusRequest(id, {status: +status === 0 ? 1 : 0}))
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>
                    {menu.autoCall[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={3}>
                                            {menu.autoCall[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15,}} align="right" colSpan={9}>

                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        >
                                            {ivr.name[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {auto.success[+localStorage.getItem('atsLang')  || 1]}

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {auto.fail[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {auto.current[+localStorage.getItem('atsLang')  || 1]}

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {auto.status[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {autoCallData.length ? autoCallData.map(s => (
                                        <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center">
                                                {s.isivr === 2 ? menu.autoCall1[+localStorage.getItem('atsLang')  || 1] + ' - ' + s.name : menu.autoCall1[+localStorage.getItem('atsLang')  || 1] + ' - ' + s.name}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.okqanak} / {s.dtmf} / {s.duration}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.rlimit}

                                            </TableCell>
                                            <TableCell align="center">
                                                {s.mnacord}
                                            </TableCell>
                                            <TableCell className="auto_status" onClick={() => handleStatus(s.id,s.autostatus)} align="center"
                                                       sx={{cursor: 'pointer'}}>
                                                {s.autostatus ? 'Running' : 'Paused'}
                                            </TableCell>
                                            <TableCell align="center" className="action"
                                            >
                                                <div style={{display: "flex", justifyContent: 'center'}}>
                                                    <Link to={'/auto_call/' + s.id} className="edit"
                                                          style={{color: '#78d417'}}><FontAwesomeIcon
                                                        icon={faPenToSquare}/></Link>
                                                    <span onClick={() => handleDelete(s.id)}
                                                          className="delete"><FontAwesomeIcon icon={faTrashCan}/></span>
                                                </div>
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                        <TableCell align="center" colSpan={7} sx={{fontSize: 13}}>
                                            no data

                                        </TableCell>
                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                </div>
                {create ? <AddAutoCall open={create} setOpen={setCreate}/> : null}
                {/*{edit ? <AddAndEditAutoCall open={edit} edit={edit} setOpen={setEdit}/> : null}*/}
            </Wrapper>
        </>

    );
}

export default AutoCall;
