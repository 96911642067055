import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import { forward, global, input, sip} from "../translate";
import {useDispatch} from "react-redux";
import {callForwardLocal, callRoutCreateRequest, callRoutEditRequest} from "../store/actions/callRout";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function AddAndEditSip({open,edit, setOpen}) {
    const [form, setForm] = useState({
        input: '',
        prefix: '',
        channel: '',
    })
    const [loading,setLoading] = useState(!!edit)
    const [channels,setChannel] = useState([])
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()
    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        if(edit){
            await dispatch(callRoutEditRequest(10,query.page || 1,query.search || '',edit,form))
        }else{
            await dispatch(callRoutCreateRequest(10,query.page || 1,query.search || '',form))
        }
        setOpen(false)
    }, [form,edit,location.search])
    useEffect(() => {
        (async () => {
            const {data} = await Api.getCreateSelectForward()
            setChannel(data.input_number.channel)
        }) ()
    },[])
    useEffect(() => {
        (async () => {
           if(edit){
               const {data} = await Api.getSingleRoute(edit)
               setForm({
                   input: data.routs.input,
                   prefix: data.routs.prefix,
                   channel: data.routs.channel + '_' +data.routs.provider,
               })
               setLoading(false)
           }
        }) ()
    },[edit])
    if (loading) return null
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {forward.call_rout[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>

                    <form onSubmit={handleAdd}>
                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>

                            <label style={{margin:'20px 0'}} className="count col-md-12">
                            <span>
                               {forward.prefix[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input list="options" placeholder="Ընտրեք..."  value={form.input} onChange={(ev) => handleChange('input', ev.target.value)}/>
                                <datalist id="options">
                                    <option value="Բջջ" />
                                    <option value="Քաղ" />
                                    <option value="Միջ" />
                                    <option value="Վճար" />
                                </datalist>
                            </label>
                            {edit? <label style={{margin:'20px 0'}} className="count col-md-12">
                            <span>
                               {forward.prefix[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input value={form.prefix} onChange={(ev) => handleChange('prefix', ev.target.value)}/>
                            </label>:null}
                            <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                               {forward.ext[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <select
                                    value={form.channel} onChange={(ev) => handleChange('channel', ev.target.value)}
                                    style={{height: 40, border: '1px solid #ccc', outline: 'none'}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {channels.map((c,_) => (
                                        <option key={_} value={c.name + '_' + (c.provider || null)}>{c.title}</option>
                                    ))}
                                </select>
                            </label>

                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {edit?global.edit[+localStorage.getItem('atsLang')  || 1 ]:sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddAndEditSip;
