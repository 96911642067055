import React, {useEffect, useState} from 'react';
import {menu,} from "../translate";
import Api from "../Api";
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";

const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 830 ? 830 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerHeight > 565 ? '80vh' : '97vh',
    overflow: 'auto'
};

function LogDetail({open, setOpen}) {
    const [single, setSingle] = useState({})
    useEffect(() => {
        (async () => {
            const {data} = await Api.getSingleLogs(open)
            console.log(JSON.parse(data.logs.data))
            setSingle(data.logs)
        })()
    }, [open])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={!!open}
            onClose={() => setOpen(0)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={!!open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.logs[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(0)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    {single.method === 'PUT'?
                        <>
                            <div>
                                <p>Before</p>
                                <pre>{single?.data ?JSON.stringify(JSON.parse(single?.data).before, null, 2):null}</pre>
                            </div>
                            <div>
                                <p>After</p>
                                <pre>{single?.data ?JSON.stringify(JSON.parse(single?.data).after, null, 2):null}</pre>
                            </div>
                        </>:
                        <div>
                            <pre>{single?.data ? JSON.stringify(JSON.parse(single?.data), null, 2):null}</pre>
                        </div>
                    }
                </Box>
            </Fade>
        </Modal>
    );
}

export default LogDetail;
