import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {historyHeader, input, menu, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from "@mui/icons-material/Search";
import {blockedDeleteLocal, blockedRequest} from "../store/actions/blocked";
import AddBlocked from "../components/AddBlocked";
import Utils from "../Utils";
import qs from "query-string";
import Api from "../Api";
import {Tooltip} from 'react-tooltip';
import {fileDeleteLocal, fileDeleteRequest} from "../store/actions/file";
import Cookies from "js-cookie";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'

function Blocked(props) {
    const blockedData = useSelector(state => state.blocked.blockedData)
    const count = useSelector(state => state.blocked.count)
    const page = useSelector(state => state.blocked.page)
    const [create, setCreate] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [copied, setCopied] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(blockedDeleteLocal(id))
                try {
                    await Api.deleteBlocked(id)

                } catch (e) {

                }
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    useEffect(() => {
        (async () => {
            await dispatch(blockedRequest(Cookies.get("blocked") || query.limit || 10, query.page || 1, query.search || ''))
        })()
    }, [location.search])

    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("blocked",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    return (
        <>
            <Helmet>
                <title>
                    {menu.block[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                            {menu.block[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end">
                                                <label className="search">
                                                    <input
                                                        value={query.search || ''}
                                                        onChange={handleSearch}
                                                        placeholder={input.search[+localStorage.getItem('atsLang')  || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">

                                        <TableCell
                                            align='center'
                                        >
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {historyHeader.phone1[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.note[+localStorage.getItem('atsLang')  || 1]}

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blockedData.length ? blockedData.map(s => (
                                        <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center">
                                                <CheckCircleIcon style={{color: color}}/>
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.phone}
                                            </TableCell>
                                            <TableCell>
                                                <span  data-tooltip-content={s.notes}
                                                       data-tooltip-id={s.id.toString()} className="single-line-text">
                                                    {s.notes}
                                                </span>
                                            </TableCell>
                                            <TableCell className="action" align="center">
                                                <p style={{display: "flex", justifyContent: 'center'}}><span
                                                    onClick={() => handleDelete(s.id)}
                                                    className="delete"><FontAwesomeIcon icon={faTrashCan}/></span></p>
                                            </TableCell>
                                            <Tooltip className={"tooltip"} id={s.id.toString()}/>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                        <TableCell align="center" colSpan={5} sx={{fontSize: 13}}>
                                            no data

                                        </TableCell>
                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className=" foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                historyHeader.phone1[+localStorage.getItem('atsLang')  || 1],
                                sip.note[+localStorage.getItem('atsLang')  || 1],
                            ], blockedData)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                historyHeader.phone1[+localStorage.getItem('atsLang')  || 1],
                                sip.note[+localStorage.getItem('atsLang')  || 1],
                            ], blockedData)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => {
                                let table = document.getElementById('dataBody');
                                if (navigator.clipboard) {
                                    setCopied(true)
                                    let text = table.innerText.trim();
                                    navigator.clipboard.writeText(text).catch(function () {
                                    });
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 1000)
                                }
                            }}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                                component="div"
                                count={+count}
                                rowsPerPage={Cookies.get("blocked") ||query.limit || 10}
                                page={+page - 1}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev,newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>
                        {copied ? <div className="copied">Copied</div> : null}

                    </Paper>

                </div>
                {create ? <AddBlocked open={create} edit={false} setOpen={setCreate}/> : null}
                {/*{edit ? <AddAndEditBlocked open={edit} edit={edit} setOpen={setEdit}/> : null}*/}
            </Wrapper>
        </>

    );
}

export default Blocked;
