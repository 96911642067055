import React, {useCallback, useEffect, useState} from 'react';
import {global, input} from "../translate";
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import Select from "react-select";
import Wrapper from "./Wrapper";
import CloseIcon from "@mui/icons-material/Close";
import {toast} from "react-toastify";

const {REACT_APP_SERVER} = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    overflow: 'hidden'
};
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
let arr = []


function YourCrm() {
    const navigate = useNavigate()
    const [sip, setSip] = useState([])
    const [crms, setCrms] = useState([])
    const [current, setCurrent] = useState({
        id: 0,
        sip: '',
        crmid: '',
        params: '',
    })
    const handleSubmit = useCallback(async (ev) => {
       try {
           ev.preventDefault()
           if(!current.params || !current.crmid || !current.sip){
               toast.error('Please fill all')
               return
           }

           const {data} = await Api.createCustomCrm(current)
           setCrms(data.data)
           toast.success(global.create[+localStorage.getItem('atsLang')])
       }catch (e) {
           toast.error(e.response.data.message)
       }
    }, [current,global])
    const handleForm = useCallback((name, value) => {
        setCurrent((prev) => ({...prev, [name]: value}))
    }, [])
    const handleEdit = useCallback(async (form) => {
        try {
            if (current.id === form.id) {
                const {data} = await Api.editCustomCrm(current)
                setCrms(data.data)
                setCurrent({
                    id: 0,
                    sip: '',
                    crmid: '',
                    params: '',
                })
                toast.success(global.success[+localStorage.getItem('atsLang')])
            } else {
                setCurrent(form)
            }
        } catch (e) {

        }
    }, [current, global])
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1 ? "Are you sure you want to delete?" :
                +localStorage.getItem('atsLang') === 2 ? "Вы уверены, что хотите судалить?" :
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await Api.deleteCustomCrm(id)
                setCrms(prev => prev.filter(p => p.id !== id))

            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        (async () => {
            const {data} = await Api.getCrmSip()
            const crm = await Api.getCrm()
            setCrms(crm.data.data)
            setSip(data.sip)
        })()
    }, [])

    return (

        <Wrapper>
            <div className="tableParent" style={{background: 'white', padding: '20px'}}>
                <form onSubmit={(ev) => ev.preventDefault()} className="d-flex flex-column justify-content-between">
                    <div className="d-flex justify-content-between">
                        <h3>{input.header[+localStorage.getItem('atsLang') || 1]}</h3>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            navigate('/crm_integration')
                        }}>
                            <CloseIcon/>
                            </span>
                    </div>
                    <div className="row">

                    </div>
                    <div className="row">
                        {crms.map(c => (
                            <div key={c.id} className="d-flex flex-row text-center"
                                 style={{width: 'fit-content', margin: '10px 0'}}>
                                <div style={{width: 200}}>
                                    <Select
                                        defaultValue={current.id === c.id ? {
                                            value: current.sip,
                                            label: `${current.sip} (${sip.find(s => +s.buddy === current.sip)?.notes})`
                                        } : {
                                            value: c.sip,
                                            label: `${c.sip} (${sip.find(s => +s.buddy === c.sip)?.notes})`
                                        }}
                                        name="colors"
                                        onChange={(ev) => handleForm('sip', ev.value)}
                                        menuPortalTarget={document.body}
                                        isDisabled={current.id !== c.id}
                                        styles={customStyles}
                                        options={sip.map(l => ({value: +l.buddy, label: `${l.buddy} (${l.notes})`}))}
                                        className="basic-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="count" style={{width: 200, margin: '0 20px'}}>
                                    <input
                                        disabled={current.id !== c.id}
                                        style={{textAlign: "center"}}
                                        value={current.id !== c.id ? c.params : current.params}
                                        onChange={(ev) => handleForm('params', ev.target.value)}/>
                                </div>
                                <div style={{width: 200}}>
                                    <select
                                        disabled={current.id !== c.id}
                                        value={current.id !== c.id ? c.crmid : current.crmid}
                                        onChange={(ev) => handleForm('crmid', ev.target.value)}
                                        style={{width: '100%', height: 35, textAlign: "center", fontSize: 14}}
                                    >
                                        <option value="">{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                        <option value="0">web-request GET</option>
                                        <option value="98">web</option>
                                        <option value="99">web-request POST</option>

                                    </select>
                                </div>
                                <div style={{width: 150}} className="d-flex justify-content-center">
                                    <button onClick={() => handleEdit(c)} className="addBtn">
                                        {current.id !== c.id ? 'Edit' : 'Save'}
                                    </button>
                                    <button onClick={() => handleDelete(c.id)} className="addBtn deleteBtn"
                                            style={{padding: 10, margin: '0 6px'}}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex flex-row text-center" style={{width: 'fit-content', margin: '10px 0'}}>

                            <div style={{width: 200}}>
                                <Select
                                    defaultValue={current.id === 0 ? {} : {
                                        value: current.sip,
                                        label: `${current.sip} (${sip.find(s => +s.buddy === current.sip)?.notes})`
                                    }}
                                    name="colors"
                                    onChange={(ev) => handleForm('sip', ev.value)}
                                    menuPortalTarget={document.body}
                                    styles={customStyles}
                                    options={sip.map(l => ({value: l.buddy, label: `${l.buddy} (${l.notes})`}))}
                                    className="basic-select"
                                    classNamePrefix="select"
                                />
                            </div>
                            <div className="count" style={{width: 200, margin: '0 20px'}}>
                                <input style={{textAlign: "center"}}
                                    value={current.id !== 0 ? '' : current.params}
                                    onChange={(ev) => handleForm('params', ev.target.value)}
                                />
                            </div>
                            <div style={{width: 200}}>
                                <select
                                    value={current.id !== 0 ? '' : current.crmid}
                                    onChange={(ev) => handleForm('crmid', ev.target.value)}
                                    style={{width: '100%', height: 35, textAlign: "center", fontSize: 14}}
                                >
                                    <option value="">{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                    <option value="0">web-request GET</option>
                                    <option value="98">web</option>
                                    <option value="99">web-request POST</option>

                                </select>
                            </div>
                            <div style={{width: 150}} className="d-flex justify-content-center">
                                <button onClick={handleSubmit} className="addBtn">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Wrapper>

    );
}

export default YourCrm;
