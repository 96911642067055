import {
    CRM_CREATE_FAIL,
    CRM_CREATE_REQUEST,
    CRM_CREATE_SUCCESS,
    CRM_FAIL,
    CRM_REQUEST, CRM_SINGLE_FAIL, CRM_SINGLE_REQUEST, CRM_SINGLE_SUCCESS,
    CRM_SUCCESS
} from '../actions/crm';

const initialState = {
    crmData: [
        [
            "0",
            "web-request GET",
            null,
            null,
            "OLD DEFOULT",
            null
        ],
        [
            "2",
            "u-on",
            "http://10.223.1.14/uon-api.php",
            null,
            null,
            "key=&manager_id="
        ],
        [
            "3",
            "bitrix24",
            "http://10.223.1.14/bitrix24-api.php",
            "start,end,record",
            null,
            "key=&adminuser=&subdomain=&userid="
        ],
        [
            "4",
            "amo",
            "http://10.223.1.14/amocrm-api.php",
            "start,end",
            null,
            "amo_secret=&amokey=&amosubdomain=&amouser="
        ],
        [
            "5",
            "y-clients",
            null,
            null,
            null,
            "token="
        ],
        [
            "6",
            "megacrm",
            null,
            null,
            null,
            null
        ],
        [
            "7",
            "retailcrm",
            null,
            null,
            null,
            null
        ],
        [
            "8",
            "Pipe_drive",
            null,
            null,
            null,
            "key=&user_id=&subdomain="
        ],
        [
            "45",
            "R_Keeper",
            null,
            null,
            null,
            null
        ],
        [
            "88",
            "Apricot",
            null,
            null,
            null,
            null
        ],
        [
            "98",
            "web",
            null,
            null,
            "OLD POST",
            null
        ],
        [
            "99",
            "web-request POST",
            null,
            null,
            "OLD GET",
            null
        ],
        [
            "100",
            "CallCenter",
            null,
            null,
            null,
            null
        ],
        [
            "200",
            "It-Spark",
            null,
            null,
            null,
            "key=&manager_id="
        ]
    ],
    member_crm: [],
    crmStatus: '',
    crmSingleData: {},
    crmSingleStatus: '',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CRM_REQUEST: {
            return {
                ...state,
                crmStatus: 'request',
                crmData: [],
            };
        }
        case CRM_SUCCESS: {
            return {
                ...state,
                crmStatus: 'success',
                crmData: action.payload.data.crm,
            };
        }

        case CRM_FAIL: {
            return {
                ...state,
                crmStatus: 'fail',
            };
        }
        case CRM_SINGLE_REQUEST: {
            return {
                ...state,
                crmStatus: 'request',
                crmSingleData: {},
            };
        }
        case CRM_SINGLE_SUCCESS: {
            return {
                ...state,
                crmStatus: 'success',
                crmSingleData: action.payload.data.crm,
            };
        }

        case CRM_SINGLE_FAIL: {
            return {
                ...state,
                crmStatus: 'fail',
                crmSingleData:{}
            };
        }
        // case CRM_CREATE_REQUEST: {
        //     return {
        //         ...state,
        //         crmStatus: 'request',
        //         member_crm: [],
        //     };
        // }
        // case CRM_CREATE_SUCCESS: {
        //     return {
        //         ...state,
        //         crmStatus: 'success',
        //         member_crm: action.payload.data.crm,
        //     };
        // }
        //
        // case CRM_CREATE_FAIL: {
        //     return {
        //         ...state,
        //         crmStatus: 'fail',
        //     };
        // }


        default: {
            return {
                ...state,
            };
        }
    }
}
