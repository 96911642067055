export const  DID_REQUEST = ' DID_REQUEST';
export const  DID_SUCCESS = ' DID_SUCCESS';
export const  DID_FAIL = ' DID_FAIL';

export function didRequest(search,limit,page) {
    return {
        type:  DID_REQUEST,
        payload: {search,limit,page},
    };
}

export const  DID_CREATE_GET_REQUEST = ' DID_CREATE_GET_REQUEST';
export const  DID_CREATE_GET_SUCCESS = ' DID_CREATE_GET_SUCCESS';
export const  DID_CREATE_GET_FAIL = ' DID_CREATE_GET_FAIL';

export function didCreateGet() {
    return {
        type:  DID_CREATE_GET_REQUEST,
        payload: { },
    };
}
export const  DID_CREATE_REQUEST = ' DID_CREATE_REQUEST';
export const  DID_CREATE_SUCCESS = ' DID_CREATE_SUCCESS';
export const  DID_CREATE_FAIL = ' DID_CREATE_FAIL';

export function didCreate(form,limit,page) {
    return {
        type:  DID_CREATE_REQUEST,
        payload: { form,limit,page },
    };
}
export const  DID_EDIT_REQUEST = ' DID_EDIT_REQUEST';
export const  DID_EDIT_SUCCESS = ' DID_EDIT_SUCCESS';
export const  DID_EDIT_FAIL = ' DID_EDIT_FAIL';

export function didEdit(form,provider,limit,page) {
    return {
        type:  DID_EDIT_REQUEST,
        payload: { form,provider,limit,page },
    };
}
export const  DID_DELETE_REQUEST = ' DID_DELETE_REQUEST';
export const  DID_DELETE_SUCCESS = ' DID_DELETE_SUCCESS';
export const  DID_DELETE_FAIL = ' DID_DELETE_FAIL';

export function didDelete(phone,provider) {
    return {
        type:  DID_DELETE_REQUEST,
        payload: { phone,provider },
    };
}

export const  DID_ADD_LOCAL = 'DID_ADD_LOCAL';
export function didLocal(data) {
    return {
        type:  DID_ADD_LOCAL,
        payload: {data},
    };
}

export const  DID_DELETE_LOCAL = 'DID_DELETE_LOCAL';
export function didDeleteLocal(id,provider) {
    return {
        type:  DID_DELETE_LOCAL,
        payload: {id,provider},
    };
}
