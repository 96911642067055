import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {forward, input, ivr, menu, short, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpShortWide, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import AddAndEditCallRout from "../components/AddAndEditCallRout";
import SearchIcon from "@mui/icons-material/Search";
import {callForwardDeleteLocal, callRoutRequest} from "../store/actions/callRout";
import Cookies from "js-cookie";
import {forwardRequest} from "../store/actions/forward";
import qs from "query-string";
import Utils from "../Utils";
import Api from "../Api";
import {sipProviderLocalDelete} from "../store/actions/sip";


function CallRout(props) {
    const callRoutData = useSelector(state => state.callRout.callRoutData)
    const count = useSelector(state => state.callRout.count)
    const page = useSelector(state => state.callRout.page)
    const [edit,setEdit] = useState(0)
    const [create,setCreate] = useState(false)
    const [copied,setCopied] = useState(false)
    const [isSearch,setIsSearch] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(callForwardDeleteLocal(id))
                try {
                    await Api.deleteCallRout(id)
                }catch (e) {

                }
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("call",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    },[])
    useEffect(() => {
        if(!Cookies.get('viewer') && !Cookies.get('original')){
            navigate('/')
        }
    },[])
    useEffect(() => {
        (async () => {
            await dispatch(callRoutRequest(Cookies.get("call") ||query.limit || 10,query.page || 1,query.search || '',query.sortBy || '', query.sortOrder || ''))
        }) ()
    },[location.search])
    const handleSort = (column) => {
        query.sortBy = column
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            query.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setSortBy(column);
            setSortOrder('asc');
            query.sortOrder = 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {forward.call_rout[+localStorage.getItem('atsLang')  || 1 ]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                            {forward.call_rout[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end">
                                                <label className="search">
                                                    <input
                                                        value={query.search || ''}
                                                        onChange={handleSearch}
                                                        placeholder={input.search[+localStorage.getItem('atsLang')  || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            onClick={() => handleSort('input')}
                                            align='center'
                                        >
                                            {forward.prefix[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sortBy === 'input' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('channel')}

                                            align='center'
                                        >
                                            {sip.out[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sortBy === 'channel' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('prefix')}

                                            align='center'
                                        >
                                            {forward.prefix[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sortBy === 'prefix' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {callRoutData.length ? callRoutData.map(s => (
                                        <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>
                                            <TableCell align="center" >
                                                {s.input}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {s.channel}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {s.prefix}

                                            </TableCell>
                                            <TableCell align="center" className="action" >
                                                <p style={{display:"flex",justifyContent:'center'}}>
                                                    <span onClick={() => setEdit(s.id)} className="edit"><FontAwesomeIcon
                                                        icon={faPenToSquare}/></span>
                                                    <span onClick={() => handleDelete(s.id)} className="delete"><FontAwesomeIcon icon={faTrashCan}/></span>
                                                </p>
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                        <TableCell align="center" colSpan={5}>
                                            no data

                                        </TableCell>
                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                forward.prefix[+localStorage.getItem('atsLang')  || 1],
                                sip.out[+localStorage.getItem('atsLang')  || 1],
                                forward.prefix[+localStorage.getItem('atsLang')  || 1],
                            ], callRoutData)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                forward.prefix[+localStorage.getItem('atsLang')  || 1],
                                sip.out[+localStorage.getItem('atsLang')  || 1],
                                forward.prefix[+localStorage.getItem('atsLang')  || 1],
                            ], callRoutData)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => {
                                let table = document.getElementById('dataBody');
                                if (navigator.clipboard) {
                                    setCopied(true)
                                    let text = table.innerText.trim();
                                    navigator.clipboard.writeText(text).catch(function () {
                                    });
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 1000)
                                }
                            }}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,{ label: 'All', value: -1 }]}
                                component="div"
                                count={count || 0}
                                rowsPerPage={Cookies.get("call") ||query.limit || 10}
                                page={page - 1 || 0}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(event, newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>
                        {copied ? <div className="copied">Copied</div> : null}


                    </Paper>

                </div>
                {create ? <AddAndEditCallRout open={create} edit={false} setOpen={setCreate}/> : null}
                {edit ? <AddAndEditCallRout open={edit} edit={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>

    );
}

export default CallRout;
