import React, {useCallback, useState} from 'react';
import Api from "../Api";
import {toast} from "react-toastify";
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {global} from "../translate";
import Cookies from "js-cookie";
import {profileRequest} from "../store/actions/users";
import {REACT_APP_API_URL} from "../config";
import {useDispatch, useSelector} from "react-redux";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '300px',
    overflow: 'auto',
};
function UrsAdd({open, setOpen,}) {
    const [number, setNumber] = useState('+374')
    const profile = useSelector(state => state.users.ursData)
    const dispatch = useDispatch()
    const [loader,setLoader] = useState(false)
    const handleSubmit = useCallback(async () => {
        try{
            if(number.length > 12){
                toast.error("Invalid number")
                return
            }
            setLoader(true)
            const {data} = await Api.register(number,location.pathname)
            Cookies.set('viewer', data.member)
            Cookies.set('original', profile.id)
            Cookies.set('key', profile.uid)
            const newData = await Api.loginAsUser(data.member, profile.id, profile.uid)
            localStorage.setItem('access_token', newData.data.access_token)
            localStorage.setItem('refresh_token', newData.data.refresh_token)
            await dispatch(profileRequest())
            window.open(REACT_APP_API_URL + '/profile?add=urs', '_blank');
            setLoader(false)
            setOpen(0)

        }catch (e) {toast.error(e.response.data.message);setLoader(false)}
    }, [open, number])

    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => setOpen(0)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Create
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen(0)}>
                            x
                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" className="test_mode">
                            <div className="row w-100">
                                <input className="create_input" value={number} onChange={(ev) => setNumber(ev.target.value)}/>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button onClick={handleSubmit} className="addBtn">
                                    {loader?"Loading...":global.createUrs[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );

}

export default UrsAdd;
