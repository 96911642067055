import React, {useCallback} from 'react';
import {Button, Fade, Paper, Popover, Popper, Typography} from "@mui/material";
import PopupState, {bindTrigger, bindPopover, bindToggle, bindPopper} from 'material-ui-popup-state';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import qs from "query-string";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
function BillingPop(billing) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleSearch = useCallback((ev) => {
        query.billing = ev;
        query.page = '';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [ location.search])
    return (
        <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
            {(popupState) => (
                <div className="d-flex align-items-center justify-content-center" style={{overflow:'hidden', width:'100%'}}>
                    <div style={{cursor:'pointer'}} className="d-flex align-items-center justify-content-center w-100 " {...bindToggle(popupState)}>
                          Billing <ArrowDropDownIcon/>
                    </div>
                 <Popper className="profile__down" {...bindPopper(popupState)} transition>
                        {({TransitionProps}) => (
                            <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                <Paper>
                                    <p onClick={() => handleSearch('')} className="flags">
                                        Բոլորը
                                    </p>
                                    {billing.billing?Object.entries(billing?.billing).map(([key, value]) => (
                                        <p onClick = {() => handleSearch(key)} key={key} className='flags'>
                                            {value}
                                        </p>
                                    )):null}
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </div>
            )}
        </PopupState>
    );
}

export default BillingPop;
