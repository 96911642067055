import React, {useCallback, useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Api from "../Api";
import Account from "../helpers/Account";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {profileRequest, profileUrsRequest} from "../store/actions/users";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import img from "../assets/img/logo22.png";
import ats from "../assets/img/logo_light.png";
import uc from "../assets/img/image_ucom.png";
import {menu} from "../translate";
import LockIcon from "@mui/icons-material/Lock";
import UrsHeader from "../components/UrsHeader";
import MatrixPop from "../components/MatrixPop";
import TestPop from "../components/TestPop";
import BillingPop from "../components/BillingPop";
import DisabledPop from "../components/DisabledPop";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
const {REACT_APP_SERVER} = process.env;

function UrsReports(props) {
    const [online, setUrsReports] = useState({})
    const navigate = useNavigate()
    const profile = useSelector(state => state.users.ursData)
    const dispatch = useDispatch()

    return (
        <>
            <Helmet>
                <title>Report</title>
            </Helmet>
            <UrsHeader />
            <div className="urs_table" style={{display: 'flex', justifyContent: 'center'}}>
                <div className="container tableParent  file" style={window.innerWidth <= 1024 ? {
                    overflow: 'auto',
                    background: 'white',
                    padding: 35
                } : {background: 'white', padding: 35}}>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <div>
                                <Link className="custom_span" to="/voip_report" style={{cursor: 'pointer', marginRight: 5}}
                                      >VoIP Report </Link>
                                |
                                <Link className="custom_span" to="/voip_in" style={{cursor: 'pointer', margin: 5}}
                                      >VoIP Mutqer</Link>
                                |
                                {/*<Link className="custom_span" style={{cursor: 'pointer', margin: 5}} to="/urs_used" target="_blank"*/}
                                {/*      rel="noopener noreferrer">{REACT_APP_SERVER === 'Beeline' ? 'Beeline' : 'V-Office'} Used</Link>*/}
                                {/*|<Link className="custom_span" style={{cursor: 'pointer', margin: 5}} to="/report">Report Traffic </Link>*/}

                            </div>

                        </div>
                    </div>
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                        {/*<tr>*/}
                        {/*    <th>NN</th>*/}
                        {/*    <th>Ամսաթիվ</th>*/}
                        {/*    <th>Օգտատեր</th>*/}
                        {/*    /!*<th><MatrixPop /></th>*!/*/}
                        {/*    <th>Հեռ</th>*/}
                        {/*    <th>Անուն Ազգանուն</th>*/}
                        {/*    <th>VIP</th>*/}
                        {/*    <th>Մնացորդ (Դրամ)</th>*/}
                        {/*    <th>Ծառայության գին</th>*/}
                        {/*    /!*<th><TestPop/></th>*!/*/}
                        {/*    /!*<th><BillingPop billing={data.billings}/></th>*!/*/}
                        {/*    /!*<th><DisabledPop /></th>*!/*/}
                        {/*    <th>Disabl. Date</th>*/}
                        {/*    <th>H</th>*/}
                        {/*    <th>R</th>*/}
                        {/*    <th>L</th>*/}
                        {/*    <th>Comment</th>*/}
                        {/*</tr>*/}
                        </thead>
                        <tbody>
                        {/*{data.members?.length ? data.members.map(d =>*/}
                        {/*    <tr key={d?.id} style={{cursor: 'pointer'}}>*/}
                        {/*        <td>{d?.id}</td>*/}
                        {/*        <td>{new Date(d?.registered).toLocaleString()}</td>*/}
                        {/*        <td onClick={() => handleId(d?.id)}>{d?.name}</td>*/}
                        {/*        <td>{d?.matrixid}</td>*/}
                        {/*        <td>{d?.phone}</td>*/}
                        {/*        <td>{d?.fio}</td>*/}
                        {/*        <td>{d?.isvip}</td>*/}
                        {/*        <td>{d?.balance}</td>*/}
                        {/*        <td>{d?.sgs} / {d?.sg}</td>*/}
                        {/*        <td>*/}
                        {/*            <span className="d-flex align-items-end">*/}
                        {/*                <input className="m-0" type="checkbox"*/}
                        {/*                       checked={d?.testmode}*/}
                        {/*                       onChange={() => handleTest(d?.id)}/>*/}
                        {/*                <span style={{fontSize:'small'}}><CalendarMonthIcon*/}
                        {/*                    onClick={() => setTest(d)} style={{fontSize: 16}}/></span></span></td>*/}
                        {/*        <td onClick={() => {*/}
                        {/*            setBilling({member: d?.id, name: data?.billings[d?.admgroup]})*/}
                        {/*        }}>*/}
                        {/*            {data?.billings[d?.admgroup]}*/}
                        {/*        </td>*/}
                        {/*        <td><span className="d-flex align-items-end justify-content-center"><input*/}
                        {/*            className="m-0" type="checkbox"*/}
                        {/*            checked={d.isblocked}*/}
                        {/*            onChange={() => handleBlock(d.id)}/></span>*/}
                        {/*        </td>*/}
                        {/*        <td>{d.sdate ? new Date(d.sdate).toLocaleString() : ''}</td>*/}
                        {/*        <td>{d.history}</td>*/}
                        {/*        <td>{d.lsum}</td>*/}
                        {/*        <td>{d.dlinesSum}</td>*/}
                        {/*        <td><span className="position-relative" onClick={() => setComment(d.id)}><ForumIcon*/}
                        {/*            style={{fontSize: 24}}/>*/}
                        {/*            {d.comment_count ? <span*/}
                        {/*                className="position-absolute comment_count">{d.comment_count}</span> : null}*/}
                        {/*        </span>*/}
                        {/*        </td>*/}
                        {/*    </tr>) : data.status === 'ok' && !data.members.length ?*/}
                        {/*    <tr>*/}
                        {/*        <td style={{textAlign: 'center'}} colSpan={17}>No Data</td>*/}
                        {/*    </tr>*/}
                        {/*    :*/}
                        {/*    <tr>*/}
                        {/*        <td style={{textAlign: 'center'}} colSpan={17}>Loading...</td>*/}
                        {/*    </tr>*/}
                        {/*}*/}

                        </tbody>

                    </table>
                </div>
            </div>

        </>
    );
}

export default UrsReports;
