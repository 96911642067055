import {IP_ADD_LOCAL, IP_CREATE_SUCCESS, IP_DELETE_LOCAL, IP_FAIL, IP_SUCCESS,} from '../actions/ip';

const initialState = {
    ipStatus: '',
    ipData: [],
    page: 1,
    total: 0,
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {

        case IP_CREATE_SUCCESS:
        case IP_SUCCESS: {
            return {
                ...state,
                ipStatus: 'ok',
                ipData:action.payload.data?.ips || [],
                page: action.payload.data.page,
                total: action.payload.data.total,
            };
        }
        case IP_FAIL: {
            return {
                ...state,
                ipStatus: 'fail',
            };
        }
        case IP_ADD_LOCAL: {
            return {
                ...state,
                ipStatus: 'success',
                // ipData: [...state.ipData, {...action.payload.data, id: new Date()},]
            };
        }
        case IP_DELETE_LOCAL: {
            return {
                ...state,
                ipStatus: 'success',
                ipData: state.ipData.filter(c => c.id !== action.payload.id)
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
