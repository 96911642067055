import {
    BLOCKED_ADD_LOCAL,
    BLOCKED_CREATE_SUCCESS,
    BLOCKED_DELETE_LOCAL,
    BLOCKED_FAIL,
    BLOCKED_SUCCESS,
} from '../actions/blocked';

const initialState = {
    blockedStatus: '',
    blockedData: [],
    page:1,
    count:0
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case BLOCKED_SUCCESS: {
            return {
                ...state,
                blockedStatus: 'ok',
                blockedData:action.payload.data.blocked,
                page:action.payload.data.page,
                count:action.payload.data.count,
            };
        }
        case BLOCKED_CREATE_SUCCESS: {
            return {
                ...state,
                blockedStatus: 'ok',
                blockedData:action.payload.data.blocked,
                page:action.payload.data.page,
                count:action.payload.data.count,
            };
        }
        case BLOCKED_FAIL: {
            return {
                ...state,
                blockedStatus: 'fail',
            };
        }
        case BLOCKED_ADD_LOCAL: {
            return {
                ...state,
                blockedStatus: 'fail',
                blockedData: [...state.blockedData, {...action.payload.data, id: new Date()},]
            };
        }
        case BLOCKED_DELETE_LOCAL: {
            return {
                ...state,
                blockedStatus: 'fail',
                blockedData: state.blockedData.filter(c => c.id !== action.payload.id)
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
