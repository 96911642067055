import React, {useCallback, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {file, global} from '../translate'
import Smile from "./Smile";
import {toast} from "react-toastify";
import {fileCreateRequest} from "../store/actions/file";
import {useDispatch} from "react-redux";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 500 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};
function FewFilesUpload({open,setOpen}) {
    const dispatch = useDispatch()

    const [form,setForm] = useState({file: []})
    const [loading,setLoading] = useState(false)
    const handleAddFew = useCallback(async (ev) => {
        toast.info('Uploading files,please wait...')
        setLoading(true)
        ev.preventDefault()
        for (let fileElement of form.file) {
            await dispatch(fileCreateRequest({file:fileElement,name:fileElement.name, size: fileElement.size}))
        }
        setLoading(false)
        setOpen(false)
    }, [form])
    const handleChange = useCallback((value) => {
        const selectedFiles = Array.from(value);
        setForm((prev) => ({...prev, file: [...prev.file, ...selectedFiles]}))
    },[])

    const handleDelete = useCallback((index) => {
        setForm((prev) => ({...prev, file: prev.file.filter((_, i) => i !== index),}))
    },[])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {global.addFew[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                <CloseIcon/>
                            </span>
                    </div>
                    <div>

                        <div style={{marginTop: '30px',}}>
                            {form.file.map((file,index) => (
                                <div key={index} style={{marginTop: '30px',}} className="d-flex flex-row justify-content-between align-items-center">
                                    <p>{file.name}</p>
                                    <span style={{cursor: 'pointer',color:'red',}} onClick={() => handleDelete(index)}><CloseIcon style={{fontSize: 15}}/></span>
                                </div>
                            ))}
                        </div>
                        <label className="addBtnBig d-flex align-items-center" style={{
                            background: '#555',
                            marginTop: '30px',
                            width: '150px',
                            height: '60px',
                            marginBottom: '10px',
                            marginRight: '50px',
                        }}>
                                <span
                                    className="forFile">{file.choose[+localStorage.getItem('atsLang') || 1]}</span>
                            <input multiple onChange={(ev) => handleChange(ev.target.files)} accept="audio/*" className="ok"
                                   style={{display: 'none'}} type="file"/>
                        </label>
                        <div style={{marginTop: '20px',marginRight:40}}>
                            <button className="addBtnBig"
                                    onClick={handleAddFew}
                                    style={{margin: '10px 0'}}>{loading?"Loading...":global.add[+localStorage.getItem('atsLang') || 1]}</button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default FewFilesUpload;