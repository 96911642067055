import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {global, input, lang} from "../translate";
import SendIcon from '@mui/icons-material/Send';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {toast} from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 400 ? 400 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '400px',
    overflow: 'auto'
};

function Comment({open, setOpen}) {
    const [comments,setComments] = useState([])
    const [comment,setComment] = useState('')

    useEffect(() => {
        (async () => {
            try{
                const {data} = await Api.comment(open)
                setComments(data.comments)
            }catch (e) {

            }
        })()
    }, [open]);

    const handleSent = useCallback(async (ev) => {
        ev.preventDefault()
        try{
            await Api.create_comment({member_id:open,comment},location.pathname)
            setComments(prevState => ([
                ...prevState,
                {messages:comment,created_at:new Date().toLocaleString(),setter:{fio:'Me'}}
            ]))
        }catch (e) {
            toast.error(e.response.data.message)
        }
    }, [open,comment])
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSent(event).then();
        }
    }, [comment,open]);
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!open}
                onClose={() => setOpen(0)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Comment
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() =>setOpen(0)}>
                            x
                        </span>
                        </div>
                        <div id="transition-modal-description sip_content" style={{height:'90%',width:'100%'}} className="test_mode justify-content-end">
                            <div className="d-flex  flex-column" style={{width:'100%'}}>
                                <div style={{height:200, overflowY:'auto'}}>
                                    {comments.length?comments.map(c => (
                                        <div style={{marginTop:10}} key={c.createdAt}>
                                            <div style={{fontSize:13}}>{new Date(c.created_at).toLocaleString()} - {c.setter.fio}</div>
                                            <div><b>{c.messages}</b></div>
                                        </div>
                                    )):<div style={{textAlign:'center'}}>No data yet</div>}
                                </div>

                                <form onKeyDown={handleKeyDown} onSubmit={handleSent} className="d-flex align-items-center" style={{margin: '10px 0',width:'100%'}}>
                                    <textarea
                                        placeholder="Leave a comment"
                                        style={{width:'100%',borderRadius: 10,padding:5}}
                                        onChange={(ev) => setComment(ev.target.value)}
                                        value={comment}
                                    />
                                    <span style={{cursor:'pointer'}} onClick={handleSent}>
                                        <SendIcon />
                                    </span>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default Comment;
